import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as ecStat from 'echarts-stat/dist/ecStat.min.js';
import { MlrOutput } from '../../models/ch6.model';

@Component({
  selector: 'app-ch6-mlr-chart',
  templateUrl: './ch6-mlr-chart.component.html',
  styleUrls: ['./ch6-mlr-chart.component.css']
})
export class Ch6MlrChartComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() mlrOutput: MlrOutput;
  option1: any;
  option2: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.option1 = this.getOption1();
    this.option2 = this.getOption2();
  }

  getOption1() {
    var dd: any[] = [];
    for (let i in this.mlrOutput.data) {
      dd.push([this.mlrOutput.data[i][1], this.mlrOutput.data[i][2]]);
    }


    let regr = ecStat.regression('linear', dd);
    regr.points.sort(function (a, b) {
      return a[0] - b[0];
    });

    return {
      title: {
        text: this.title + ' for ' + this.mlrOutput.tickers.toString(),
        subtext: 'R2 = ' + this.mlrOutput.rSquared.toFixed(4),
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      dataset: {
        source: this.mlrOutput.data
      },
      xAxis: {
        name: this.title + ' Component',
        nameLocation: 'center',
        nameGap: 30,
        scale: true,
        axisLine: { onZero: false }
      },
      yAxis: {
        name: this.mlrOutput.tickers[0],
        nameLocation: 'center',
        nameGap: 40,
        scale: true,
        axisLine: { onZero: false }
      },
      series: [
        {
          type: 'line',
          data: regr.points,
          showSymbol: false,
          zlevel: 10
        },
        {
          type: 'scatter',
          encode: {
            x: 1,
            y: 2   
          },
          symbolSize: 6,
          zlevel: 0
        }
      ]
    }
  }

  getOption2() {
    return {
      title: {
        text: this.title + ' Price',
        left: 'center'
      },
      legend: {
        right: '50px',
        top: '70px',
        orient: 'vertical'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      dataset: {
        source: this.mlrOutput.data
      },
      xAxis: {
        name: 'Date',
        type: 'category',
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        axisLabel: {
          formatter: function (value, index) {
            return value.toString().substring(0, 10);
          }
        },
        nameLocation: 'center',
        nameGap: 30,
      },
      yAxis: {
        name: 'Price',
        scale: true,
        axisLine: { onZero: false },
        nameLocation: 'center',
        nameGap: 35,
      },
      series: [          
        {
          name: 'Original ' + this.mlrOutput.tickers[0] + ' Data',
          type: 'line',
          encode: {
            x: 0,
            y: 2
          },
          showSymbol: false,
        }, {
          name: this.title + ' Component',
          type: 'line',
          encode: {
            x: 0,
            y: 1
          },
          showSymbol: false,
        }
      ]
    }
  }
}
