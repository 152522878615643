export class ModelHelper {
  setColDefs(data: any, colWidth: number) {
    var keys = Object.keys(data[0]);
    let cols = [];
    for (let k of keys) {
      cols.push({ headerName: this.FirstLetterCapitalize(k), field: k, width: colWidth });
    }
    return cols;
  }

  private FirstLetterCapitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
}

