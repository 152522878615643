import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple3d',
  templateUrl: './simple3d.component.html',
  styleUrls: ['./simple3d.component.css']
})
export class Simple3dComponent implements OnInit { 
  options: any;

  constructor() { }

  ngOnInit() {
    this.options = this.getOptions();
  }

  getOptions() {
    return {
      tooltip: {},
      backgroundColor: '#fff',
      visualMap: {
        show: false,
        dimension: 2,
        top: '5%',
        min: -7,
        max: 8,
        inRange: {
          color: ["#00008F", "#0000FF", "#006FFF", "#00DFFF", "#4FFFAF", "#BFFF3F", "#FFCF00", "#FF5F00", "#EF0000", "#7F0000"]
        }
      },
      xAxis3D: {
        type: 'value',
        name: 'X'
      },
      yAxis3D: {
        type: 'value',
        name: 'Y'
      },
      zAxis3D: {
        type: 'value',
        name: 'Z',
        min: -7,
        max: 8
      },
      grid3D: {
        viewControl: {
          projection: 'perspective',
        },
        boxHeight: 70,
        top: '-10%'
      },
      series: [{
        type: 'surface',
        equation: {
          x: {
            min: -3,
            max: 3,
            step: 0.2
          },
          y: {
            min: -3,
            max: 3,
            step: 0.2
          },
          z: function (x, y) {
            return 3 * Math.pow((1 - x), 2) * Math.exp(-(x * x) - (y + 1) * (y + 1))
              - 10 * (x / 5 - Math.pow(x, 3) - Math.pow(y, 5)) * Math.exp(-x * x - y * y)
              - 1 / 3 * Math.exp(-(x + 1) * (x + 1) - y * y);
          }
        }
      }]
    }
  }
}
