import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit {
  vertical: any;
  horizontal: any;
  group: any;
  stack: any;

  constructor() { }

  ngOnInit() {    
    this.vertical = this.getVertical();
    this.horizontal = this.getHorizontal();
    this.group = this.getGroup();
    this.stack = this.getStack();
  }

  getVertical() {
    return {
      title: {
        text: 'Vertical Bar Chart',
        left: 'center'
      },
      tooltip: {},
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'bar'
      }]
    };   
  }

  getHorizontal() {
    return {
      title: {
        text: 'Horizontal Bar Chart',
        left: 'center'
      },
      tooltip: {},
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      series: [{
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'bar'
      }]
    };
  }

  getGroup() {
    return {
      title: {
        text: 'Group Bar Chart',
        left: 'center'
      },
      legend: {
        top: '15%',
        left: '15%',
        data: ['Houston', 'Los Angeles', 'New York', 'Chicago', 'Philadephia']
      },
      tooltip: {},
      xAxis: [
        {
          type: 'category',
          data: ['1990', '2000', '2005', '2010', '2015']
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Population (in millions)',
          nameLocation: 'center',
          nameGap: '25'
        }
      ],
      series: [
        {
          name: 'Houston',
          type: 'bar',
          data: [1.70, 1.98, 2.08, 2.10, 2.30],
        }, {
          name: 'Los Angeles',
          type: 'bar',
          data: [3.49, 3.70, 3.79, 3.79, 3.97]
        }, {
          name: 'New York',
          type: 'bar',
          data: [7.32, 8.02, 8.21, 8.18, 8.55]
        }, {
          name: 'Chicago',
          type: 'bar',
          data: [2.78, 2.90, 2.82, 2.70, 2.72]
        }, {
          name: 'Philadephia',
          type: 'bar',
          data: [1.59, 1.51, 1.52, 1.53, 1.57]
        }
      ]
    }
  }

  getStack() {
    return {
      title: {
        text: 'Stack',
      },
      legend: {
        left: '20%',
        data: ['Houston', 'Los Angeles', 'New York', 'Chicago', 'Philadephia']
      },
      tooltip: {},
      xAxis: [
        {
          type: 'category',
          data: ['1990', '2000', '2005', '2010', '2015']
        }],
      yAxis: [
        {
          type: 'value',
          name: 'Population (in millions)',
          nameLocation: 'center',
          nameGap: '25'
        }],
      series: [
        {
          name: 'Houston',
          type: 'bar',
          stack: 'Total',
          data: [1.70, 1.98, 2.08, 2.10, 2.30]          
        }, {
          name: 'Los Angeles',
          type: 'bar',
          data: [3.49, 3.70, 3.79, 3.79, 3.97],
          stack: 'Total'
        }, {
          name: 'New York',
          type: 'bar',
          stack: 'Total',
          data: [7.32, 8.02, 8.21, 8.18, 8.55]
        }, {
          name: 'Chicago',
          type: 'bar',
          stack: 'Total',
          data: [2.78, 2.90, 2.82, 2.70, 2.72]
        }, {
          name: 'Philadephia',
          type: 'bar',
          stack: 'Total',
          data: [1.59, 1.51, 1.52, 1.53, 1.57]
        }]
    }
  }
}
