import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class Ch9Service {
  private url;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
  }

  getEuropeanOption(optionType: string, yearsToMaturity: number, strike: number, spot: number, q: number, r: number, vol: number,
    engineType: string, timeSteps: number) {
    let url1 = this.url + 'api/europeanoption/' + optionType + '/' + yearsToMaturity + '/' + strike + '/' + spot + '/' + q +
      '/' + r + '/' + vol + '/' + engineType + '/' + timeSteps;
    return this.http.get<any>(url1);
  }

  getEuropeanOptions(optionType: string, strike: number, spot: number, q: number, r: number, vol: number,
    engineType: string, timeSteps: number) {
    let url1 = this.url + 'api/europeanoptions/' + optionType + '/' + strike + '/' + spot + '/' + q +
      '/' + r + '/' + vol + '/' + engineType + '/' + timeSteps;
    return this.http.get<any>(url1);
  }

  getEuropeanImpliedVol(optionType: string, yearsToMaturity: number, strike: number, spot: number, q: number, r: number, targetPrice: number) {
    let url1 = this.url + 'api/europeanimpliedvol/' + optionType + '/' + yearsToMaturity + '/' + strike + '/' + spot + '/' + q +
      '/' + r + '/' + targetPrice;
    return this.http.get<any>(url1);
  }

  getEuropeanImpliedVols(optionType: string, strike: number, spot: number, q: number, r: number) {
    let url1 = this.url + 'api/europeanimpliedvols/' + optionType + '/' + strike + '/' + spot + '/' + q + '/' + r;
    return this.http.get<any>(url1);
  }

  getAmericanOption(optionType: string, yearsToMaturity: number, strike: number, spot: number, q: number, r: number, vol: number,
    engineType: string, timeSteps: number) {
    let url1 = this.url + 'api/americanoption/' + optionType + '/' + yearsToMaturity + '/' + strike + '/' + spot + '/' + q +
      '/' + r + '/' + vol + '/' + engineType + '/' + timeSteps;
    return this.http.get<any>(url1);
  }

  getAmericanImpliedVol(optionType: string, yearsToMaturity: number, strike: number, spot: number, q: number, r: number, targetPrice: number) {
    let url1 = this.url + 'api/americanimpliedvol/' + optionType + '/' + yearsToMaturity + '/' + strike + '/' + spot + '/' + q +
      '/' + r + '/' + targetPrice;
    return this.http.get<any>(url1);
  }

  getAmericanOptionTest() {
    return this.http.get<any>(this.url + 'api/americanoptiontest');
  }

  getBarrierOption(optionType: string, yearsToMaturity: number, strike: number, spot: number, barrierLevel: number, rebate: number,
    q: number, r: number, vol: number, barrierType: string, engineType: string) {
    let url1 = this.url + 'api/barrieroption/' + optionType + '/' + yearsToMaturity + '/' + strike + '/' + spot + '/' + barrierLevel + '/'
      + rebate + '/' + q + '/' + r + '/' + vol + '/' + barrierType + '/' + engineType;
    return this.http.get<any>(url1);
  }

  getBarrierOptions(optionType: string, strike: number, spot: number, barrierLevel: number, rebate: number,
    q: number, r: number, vol: number, engineType: string) {
    let url1 = this.url + 'api/barrieroptions/' + optionType + '/' + strike + '/' + spot + '/' + barrierLevel + '/'
      + rebate + '/' + q + '/' + r + '/' + vol + '/' + engineType;
    return this.http.get<any>(url1);
  }

  getBermudanOption(optionType: string, exerciseFrequency: number, exerciseTimes: number, strike: number, spot: number,
    q: number, r: number, vol: number, engineType: string, timeSteps) {
    let url1 = this.url + 'api/bermudanoption/' + optionType + '/' + exerciseFrequency + '/' + exerciseTimes + '/' + strike + '/'
      + spot + '/' + q + '/' + r + '/' + vol + '/' + engineType + '/' + timeSteps;
    return this.http.get<any>(url1);
  }

  getBermudanOptions(optionType: string, exerciseFrequency: number, exerciseTimes: number, spot: number,
    q: number, r: number, vol: number, engineType: string, timeSteps) {
    let url1 = this.url + 'api/bermudanoptions/' + optionType + '/' + exerciseFrequency + '/' + exerciseTimes +
      '/' + spot + '/' + q + '/' + r + '/' + vol + '/' + engineType + '/' + timeSteps;
    return this.http.get<any>(url1);
  }

  getAmericanOptionRealWorld(engineType: string) {
    return this.http.get<any>(this.url + 'api/americanoptionrealworld/' + engineType);
  }
}
