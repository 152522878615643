import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material/material.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { AngularDraggableModule } from 'angular2-draggable';
import { AgGridModule } from 'ag-grid-angular';
import { KatexModule } from 'ng-katex';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './chapter01/counter/counter.component'
import { FetchDataComponent } from './chapter01/fetch-data/fetch-data.component';
import { TestMaterialComponent } from './chapter01/test-material/test-material.component';
import { InterpolationComponent } from './chapter02/interpolation/interpolation.component';
import { OneWayBindingComponent } from './chapter02/one-way-binding/one-way-binding.component';
import { TwoWayBindingComponent } from './chapter02/two-way-binding/two-way-binding.component';
import { EventBindingComponent } from './chapter02/event-binding/event-binding.component';
import { DirectiveTestComponent } from './chapter02/directive-test/directive-test.component';
import { HighlightDirective } from './chapter02/highlight.directive';
import { InputOutputComponent } from './chapter02/input-output/input-output.component';
import { StockListComponent } from './chapter02/input-output/stock-list/stock-list.component';
import { StockDetailComponent } from './chapter02/input-output/stock-detail/stock-detail.component';
import { AllStocksComponent } from './chapter03/all-stocks/all-stocks.component';
import { IndexDataComponent } from './chapter03/index-data/index-data.component';
import { StockCrudComponent } from './chapter03/stock-crud/stock-crud.component';
import { StockPriceComponent } from './chapter03/stock-price/stock-price.component';
import { StockDeleteComponent } from './chapter03/stock-crud/stock-delete/stock-delete.component';
import { StockCreateComponent } from './chapter03/stock-crud/stock-create/stock-create.component';
import { StockUpdateComponent } from './chapter03/stock-crud/stock-update/stock-update.component';
import { StockPriceChildComponent } from './chapter03/stock-price/stock-price-child/stock-price-child.component';
import { StockDeleteDialogComponent } from './chapter03/stock-crud/stock-delete/stock-delete-dialog/stock-delete-dialog.component';
import { YahooStockComponent } from './chapter04/yahoo-stock/yahoo-stock.component';
import { YahooStockSaveComponent } from './chapter04/yahoo-stock-save/yahoo-stock-save.component';
import { IexStockComponent } from './chapter04/iex-stock/iex-stock.component';
import { IexQuoteComponent } from './chapter04/iex-quote/iex-quote.component';
import { QuandlStockComponent } from './chapter04/quandl-stock/quandl-stock.component';
import { AvStockComponent } from './chapter04/av-stock/av-stock.component';
import { AvBarComponent } from './chapter04/av-bar/av-bar.component';
import { AvQuoteComponent } from './chapter04/av-quote/av-quote.component';
import { AvFxComponent } from './chapter04/av-fx/av-fx.component';
import { AvFxBarComponent } from './chapter04/av-fx-bar/av-fx-bar.component';
import { AvSectorComponent } from './chapter04/av-sector/av-sector.component';
import { IsdaComponent } from './chapter04/isda/isda.component';
import { ChartLineComponent } from './chapter05/chart-line/chart-line.component';
import { SimpleLineComponent } from './chapter05/chart-line/simple-line/simple-line.component';
import { MultipleLinesComponent } from './chapter05/chart-line/multiple-lines/multiple-lines.component';
import { Y2AxisComponent } from './chapter05/chart-line/y2-axis/y2-axis.component';
import { ResizeComponent } from './chapter05/chart-line/resize/resize.component';
import { ChartSpecializedComponent } from './chapter05/chart-specialized/chart-specialized.component';
import { BarChartComponent } from './chapter05/chart-specialized/bar-chart/bar-chart.component';
import { PieChartComponent } from './chapter05/chart-specialized/pie-chart/pie-chart.component';
import { PolarChartComponent } from './chapter05/chart-specialized/polar-chart/polar-chart.component';
import { AreaChartComponent } from './chapter05/chart-specialized/area-chart/area-chart.component';
import { Chart3dComponent } from './chapter05/chart3d/chart3d.component';
import { Line3dComponent } from './chapter05/chart3d/line3d/line3d.component';
import { Simple3dComponent } from './chapter05/chart3d/simple3d/simple3d.component';
import { Parametric3dComponent } from './chapter05/chart3d/parametric3d/parametric3d.component';
import { ChartStockComponent } from './chapter05/chart-stock/chart-stock.component';
import { CandleChartComponent } from './chapter05/chart-stock/candle-chart/candle-chart.component';
import { CandleVolumeComponent } from './chapter05/chart-stock/candle-volume/candle-volume.component';
import { RealtimeStockComponent } from './chapter05/realtime-stock/realtime-stock.component';
import { Ch6SlrIndexComponent } from './chapter06/ch6-slr-index/ch6-slr-index.component';
import { Ch6SlrChartComponent } from './chapter06/ch6-slr-chart/ch6-slr-chart.component';
import { Ch6SlrStockComponent } from './chapter06/ch6-slr-stock/ch6-slr-stock.component';
import { Ch6Pca2dIndexComponent } from './chapter06/ch6-pca2d-index/ch6-pca2d-index.component';
import { Ch6Pca2dStockComponent } from './chapter06/ch6-pca2d-stock/ch6-pca2d-stock.component';
import { Ch6MlrIndexComponent } from './chapter06/ch6-mlr-index/ch6-mlr-index.component';
import { Ch6MlrChartComponent } from './chapter06/ch6-mlr-chart/ch6-mlr-chart.component';
import { Ch6MlrStockComponent } from './chapter06/ch6-mlr-stock/ch6-mlr-stock.component';
import { Ch6PcaIndexComponent } from './chapter06/ch6-pca-index/ch6-pca-index.component';
import { Ch6PcaStockComponent } from './chapter06/ch6-pca-stock/ch6-pca-stock.component';
import { Ch7Ind2ChartComponent } from './chapter07/ch7-ind2-chart/ch7-ind2-chart.component';
import { Ch7Ind3ChartComponent } from './chapter07/ch7-ind3-chart/ch7-ind3-chart.component';
import { Ch7IndMaComponent } from './chapter07/ch7-ind-ma/ch7-ind-ma.component';
import { Ch7IndBbandsComponent } from './chapter07/ch7-ind-bbands/ch7-ind-bbands.component';
import { Ch7IndSarComponent } from './chapter07/ch7-ind-sar/ch7-ind-sar.component';
import { Ch7IndAdxComponent } from './chapter07/ch7-ind-adx/ch7-ind-adx.component';
import { Ch7IndApoComponent } from './chapter07/ch7-ind-apo/ch7-ind-apo.component';
import { Ch7IndAroonComponent } from './chapter07/ch7-ind-aroon/ch7-ind-aroon.component';
import { Ch7IndBopComponent } from './chapter07/ch7-ind-bop/ch7-ind-bop.component';
import { Ch7IndCciComponent } from './chapter07/ch7-ind-cci/ch7-ind-cci.component';
import { Ch7IndMacdComponent } from './chapter07/ch7-ind-macd/ch7-ind-macd.component';
import { Ch7IndRsiComponent } from './chapter07/ch7-ind-rsi/ch7-ind-rsi.component';
import { Ch7IndStochComponent } from './chapter07/ch7-ind-stoch/ch7-ind-stoch.component';
import { Ch7IndWillrComponent } from './chapter07/ch7-ind-willr/ch7-ind-willr.component';
import { Ch7IndAdComponent } from './chapter07/ch7-ind-ad/ch7-ind-ad.component';
import { Ch7IndObvComponent } from './chapter07/ch7-ind-obv/ch7-ind-obv.component';
import { Ch7IndAtrComponent } from './chapter07/ch7-ind-atr/ch7-ind-atr.component';
import { Ch7IndNatrComponent } from './chapter07/ch7-ind-natr/ch7-ind-natr.component';
import { Ch7IndTrComponent } from './chapter07/ch7-ind-tr/ch7-ind-tr.component';
import { RealtimeServerComponent } from './chapter05/realtime-server/realtime-server.component';
import { Ch8KnnClassComponent } from './chapter08/ch8-knn-class/ch8-knn-class.component';
import { Ch8MlDataComponent } from './chapter08/ch8-ml-data/ch8-ml-data.component';
import { Ch8SvmClassComponent } from './chapter08/ch8-svm-class/ch8-svm-class.component';
import { Ch8SvmRegrComponent } from './chapter08/ch8-svm-regr/ch8-svm-regr.component';
import { Ch8RegrChartComponent } from './chapter08/ch8-regr-chart/ch8-regr-chart.component';
import { Ch8ClassResComponent } from './chapter08/ch8-class-res/ch8-class-res.component';
import { Ch8AnnClassComponent } from './chapter08/ch8-ann-class/ch8-ann-class.component';
import { Ch8AnnRegrComponent } from './chapter08/ch8-ann-regr/ch8-ann-regr.component';
import { Ch9OptionEuComponent } from './chapter09/ch9-option-eu/ch9-option-eu.component';
import { Ch9VolEuComponent } from './chapter09/ch9-vol-eu/ch9-vol-eu.component';
import { Ch9OptionAmComponent } from './chapter09/ch9-option-am/ch9-option-am.component';
import { Ch9OptionBarrierComponent } from './chapter09/ch9-option-barrier/ch9-option-barrier.component';
import { Ch9OptionBermudanComponent } from './chapter09/ch9-option-bermudan/ch9-option-bermudan.component';
import { Ch9OptionRealworldComponent } from './chapter09/ch9-option-realworld/ch9-option-realworld.component';
import { Ch10SimpleBondComponent } from './chapter10/ch10-simple-bond/ch10-simple-bond.component';
import { Ch10ZcDirectComponent } from './chapter10/ch10-zc-direct/ch10-zc-direct.component';
import { Ch10ZcBootstrapComponent } from './chapter10/ch10-zc-bootstrap/ch10-zc-bootstrap.component';
import { Ch10LineChartComponent } from './chapter10/ch10-line-chart/ch10-line-chart.component';
import { Ch10ZcInterbankComponent } from './chapter10/ch10-zc-interbank/ch10-zc-interbank.component';
import { Ch10ZcZspreadComponent } from './chapter10/ch10-zc-zspread/ch10-zc-zspread.component';
import { Ch10LineChart2Component } from './chapter10/ch10-line-chart2/ch10-line-chart2.component';
import { Ch10CallableBondComponent } from './chapter10/ch10-callable-bond/ch10-callable-bond.component';
import { Ch10ConvBondComponent } from './chapter10/ch10-conv-bond/ch10-conv-bond.component';
import { Ch10CdsHazardComponent } from './chapter10/ch10-cds-hazard/ch10-cds-hazard.component';
import { Ch10LineChart3Component } from './chapter10/ch10-line-chart3/ch10-line-chart3.component';
import { Ch10CdsPvComponent } from './chapter10/ch10-cds-pv/ch10-cds-pv.component';
import { Ch10CdsPriceComponent } from './chapter10/ch10-cds-price/ch10-cds-price.component';
import { Ch11CrossoverMaComponent } from './chapter11/ch11-crossover-ma/ch11-crossover-ma.component';
import { Ch11CrossoverMaNatrComponent } from './chapter11/ch11-crossover-ma-natr/ch11-crossover-ma-natr.component';
import { Ch11ZscoreMaComponent } from './chapter11/ch11-zscore-ma/ch11-zscore-ma.component';
import { Ch11ZscoreRsiComponent } from './chapter11/ch11-zscore-rsi/ch11-zscore-rsi.component';
import { Ch11ZscoreMacdComponent } from './chapter11/ch11-zscore-macd/ch11-zscore-macd.component';
import { Ch11ZscorePpoComponent } from './chapter11/ch11-zscore-ppo/ch11-zscore-ppo.component';
import { Ch11PnlCrossoverComponent } from './chapter11/ch11-pnl-crossover/ch11-pnl-crossover.component';
import { Ch11PnlZscoreComponent } from './chapter11/ch11-pnl-zscore/ch11-pnl-zscore.component';
import { AvKeyComponent } from './chapter04/av-key/av-key.component';
import { QuandlKeyComponent } from './chapter04/quandl-key/quandl-key.component';
import { GetApiKeyComponent } from './chapter04/get-api-key/get-api-key.component';
import { GetApiKeyQuandlComponent } from './chapter04/get-api-key-quandl/get-api-key-quandl.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    TestMaterialComponent,
    InterpolationComponent,
    OneWayBindingComponent,
    TwoWayBindingComponent,
    EventBindingComponent,
    DirectiveTestComponent,
    HighlightDirective,
    StockListComponent,
    StockDetailComponent,
    InputOutputComponent,
    AllStocksComponent,
    IndexDataComponent,
    StockCrudComponent,
    StockPriceComponent,
    StockDeleteComponent,
    StockCreateComponent,
    StockUpdateComponent,
    StockPriceChildComponent,
    StockDeleteDialogComponent,
    YahooStockComponent,
    YahooStockSaveComponent,
    IexStockComponent,
    IexQuoteComponent,
    QuandlStockComponent,
    AvStockComponent,
    AvBarComponent,
    AvQuoteComponent,
    AvFxComponent,
    AvFxBarComponent,
    AvSectorComponent,
    IsdaComponent,
    ChartLineComponent,
    SimpleLineComponent,
    MultipleLinesComponent,
    Y2AxisComponent,
    ResizeComponent,
    ChartSpecializedComponent,
    BarChartComponent,
    PieChartComponent,
    PolarChartComponent,
    AreaChartComponent,
    Chart3dComponent,
    Line3dComponent,
    Simple3dComponent,
    Parametric3dComponent,
    ChartStockComponent,
    CandleChartComponent,
    CandleVolumeComponent,
    RealtimeStockComponent,
    Ch6SlrIndexComponent,
    Ch6SlrChartComponent,
    Ch6SlrStockComponent,
    Ch6Pca2dIndexComponent,
    Ch6Pca2dStockComponent,
    Ch6MlrIndexComponent,
    Ch6MlrChartComponent,
    Ch6MlrStockComponent,
    Ch6PcaIndexComponent,
    Ch6PcaStockComponent,
    Ch7Ind2ChartComponent,
    Ch7Ind3ChartComponent,
    Ch7IndMaComponent,
    Ch7IndBbandsComponent,
    Ch7IndSarComponent,
    Ch7IndAdxComponent,
    Ch7IndApoComponent,
    Ch7IndAroonComponent,
    Ch7IndBopComponent,
    Ch7IndCciComponent,
    Ch7IndMacdComponent,
    Ch7IndRsiComponent,
    Ch7IndStochComponent,
    Ch7IndWillrComponent,
    Ch7IndAdComponent,
    Ch7IndObvComponent,
    Ch7IndAtrComponent,
    Ch7IndNatrComponent,
    Ch7IndTrComponent,
    RealtimeServerComponent,
    Ch8KnnClassComponent,
    Ch8MlDataComponent,
    Ch8SvmClassComponent,
    Ch8SvmRegrComponent,
    Ch8RegrChartComponent,
    Ch8ClassResComponent,
    Ch8AnnClassComponent,
    Ch8AnnRegrComponent,
    Ch9OptionEuComponent,
    Ch9VolEuComponent,
    Ch9OptionAmComponent,
    Ch9OptionBarrierComponent,
    Ch9OptionBermudanComponent,
    Ch9OptionRealworldComponent,
    Ch10SimpleBondComponent,
    Ch10ZcDirectComponent,
    Ch10ZcBootstrapComponent,
    Ch10LineChartComponent,
    Ch10ZcInterbankComponent,
    Ch10ZcZspreadComponent,
    Ch10LineChart2Component,
    Ch10CallableBondComponent,
    Ch10ConvBondComponent,
    Ch10CdsHazardComponent,
    Ch10LineChart3Component,
    Ch10CdsPvComponent,
    Ch10CdsPriceComponent,
    Ch11CrossoverMaComponent,
    Ch11CrossoverMaNatrComponent,
    Ch11ZscoreMaComponent,
    Ch11ZscoreRsiComponent,
    Ch11ZscoreMacdComponent,
    Ch11ZscorePpoComponent,
    Ch11PnlCrossoverComponent,
    Ch11PnlZscoreComponent,
    AvKeyComponent,
    QuandlKeyComponent,
    GetApiKeyComponent,
    GetApiKeyQuandlComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    NgxEchartsModule,
    AngularDraggableModule,
    AgGridModule.withComponents(null),
    KatexModule,
    RouterModule.forRoot([      
      { path: 'chapter01/counter', component: CounterComponent },
      { path: 'chapter01/fetch-data', component: FetchDataComponent },
      { path: 'chapter02/interpolation', component: InterpolationComponent },
      { path: 'chapter02/one-way-binding', component: OneWayBindingComponent },
      { path: 'chapter02/event-binding', component: EventBindingComponent },
      { path: 'chapter02/two-way-binding', component: TwoWayBindingComponent },
      { path: 'chapter02/directive-test', component: DirectiveTestComponent },
      { path: 'chapter02/input-output', component: InputOutputComponent },
      { path: 'chapter03/all-stocks', component: AllStocksComponent },
      { path: 'chapter03/stock-price', component: StockPriceComponent },
      { path: 'chapter03/index-data', component: IndexDataComponent },
      { path: 'chapter03/stock-crud', component: StockCrudComponent },
      { path: 'chapter04/get-api-key', component: GetApiKeyComponent },
      { path: 'chapter04/get-api-key-quandl', component: GetApiKeyQuandlComponent },
      { path: 'chapter04/yahoo-stock', component: YahooStockComponent },
      { path: 'chapter04/yahoo-stock-save', component: YahooStockSaveComponent },
      { path: 'chapter04/iex-stock', component: IexStockComponent },
      { path: 'chapter04/iex-quote', component: IexQuoteComponent },
      { path: 'chapter04/quandl-stock', component: QuandlStockComponent },
      { path: 'chapter04/av-stock', component: AvStockComponent },
      { path: 'chapter04/av-bar', component: AvBarComponent },
      { path: 'chapter04/av-quote', component: AvQuoteComponent },
      { path: 'chapter04/av-fx', component: AvFxComponent },
      { path: 'chapter04/av-fx-bar', component: AvFxBarComponent },
      { path: 'chapter04/av-sector', component: AvSectorComponent },
      { path: 'chapter04/isda', component: IsdaComponent },
      { path: 'chapter05/chart-line', component: ChartLineComponent },
      { path: 'chapter05/chart-specialized', component: ChartSpecializedComponent },
      { path: 'chapter05/chart3d', component: Chart3dComponent },
      { path: 'chapter05/chart-stock', component: ChartStockComponent },
      { path: 'chapter05/realtime-stock', component: RealtimeStockComponent },
      { path: 'chapter05/realtime-server', component: RealtimeServerComponent },
      { path: 'chapter06/ch6-slr-index', component: Ch6SlrIndexComponent },
      { path: 'chapter06/ch6-slr-stock', component: Ch6SlrStockComponent },
      { path: 'chapter06/ch6-pca2d-index', component: Ch6Pca2dIndexComponent },
      { path: 'chapter06/ch6-pca2d-stock', component: Ch6Pca2dStockComponent },
      { path: 'chapter06/ch6-mlr-index', component: Ch6MlrIndexComponent },
      { path: 'chapter06/ch6-mlr-stock', component: Ch6MlrStockComponent },
      { path: 'chapter06/ch6-pca-index', component: Ch6PcaIndexComponent },
      { path: 'chapter06/ch6-pca-stock', component: Ch6PcaStockComponent },
      { path: 'chapter07/ch7-ind-ma', component: Ch7IndMaComponent },
      { path: 'chapter07/ch7-ind-bbands', component: Ch7IndBbandsComponent },
      { path: 'chapter07/ch7-ind-sar', component: Ch7IndSarComponent },
      { path: 'chapter07/ch7-ind-adx', component: Ch7IndAdxComponent },
      { path: 'chapter07/ch7-ind-apo', component: Ch7IndApoComponent },
      { path: 'chapter07/ch7-ind-aroon', component: Ch7IndAroonComponent },
      { path: 'chapter07/ch7-ind-bop', component: Ch7IndBopComponent },
      { path: 'chapter07/ch7-ind-cci', component: Ch7IndCciComponent },
      { path: 'chapter07/ch7-ind-macd', component: Ch7IndMacdComponent },
      { path: 'chapter07/ch7-ind-rsi', component: Ch7IndRsiComponent },
      { path: 'chapter07/ch7-ind-stoch', component: Ch7IndStochComponent },
      { path: 'chapter07/ch7-ind-willr', component: Ch7IndWillrComponent },
      { path: 'chapter07/ch7-ind-ad', component: Ch7IndAdComponent },
      { path: 'chapter07/ch7-ind-obv', component: Ch7IndObvComponent },
      { path: 'chapter07/ch7-ind-atr', component: Ch7IndAtrComponent },
      { path: 'chapter07/ch7-ind-natr', component: Ch7IndNatrComponent },
      { path: 'chapter07/ch7-ind-tr', component: Ch7IndTrComponent },
      { path: 'chapter08/ch8-ml-data', component: Ch8MlDataComponent },
      { path: 'chapter08/ch8-knn-class', component: Ch8KnnClassComponent },
      { path: 'chapter08/ch8-svm-class', component: Ch8SvmClassComponent },
      { path: 'chapter08/ch8-svm-regr', component: Ch8SvmRegrComponent },
      { path: 'chapter08/ch8-ann-class', component: Ch8AnnClassComponent },
      { path: 'chapter08/ch8-ann-regr', component: Ch8AnnRegrComponent },
      { path: 'chapter09/ch9-option-eu', component: Ch9OptionEuComponent },
      { path: 'chapter09/ch9-vol-eu', component: Ch9VolEuComponent },
      { path: 'chapter09/ch9-option-am', component: Ch9OptionAmComponent },
      { path: 'chapter09/ch9-option-barrier', component: Ch9OptionBarrierComponent },
      { path: 'chapter09/ch9-option-bermudan', component: Ch9OptionBermudanComponent },
      { path: 'chapter09/ch9-option-realworld', component: Ch9OptionRealworldComponent },
      { path: 'chapter10/ch10-simple-bond', component: Ch10SimpleBondComponent },
      { path: 'chapter10/ch10-zc-direct', component: Ch10ZcDirectComponent },
      { path: 'chapter10/ch10-zc-bootstrap', component: Ch10ZcBootstrapComponent },
      { path: 'chapter10/ch10-zc-interbank', component: Ch10ZcInterbankComponent },
      { path: 'chapter10/ch10-zc-zspread', component: Ch10ZcZspreadComponent },
      { path: 'chapter10/ch10-callable-bond', component: Ch10CallableBondComponent },
      { path: 'chapter10/ch10-conv-bond', component: Ch10ConvBondComponent },
      { path: 'chapter10/ch10-cds-hazard', component: Ch10CdsHazardComponent },
      { path: 'chapter10/ch10-cds-pv', component: Ch10CdsPvComponent },
      { path: 'chapter10/ch10-cds-price', component: Ch10CdsPriceComponent },
      { path: 'chapter11/ch11-crossover-ma', component: Ch11CrossoverMaComponent },
      { path: 'chapter11/ch11-crossover-ma-natr', component: Ch11CrossoverMaNatrComponent },
      { path: 'chapter11/ch11-zscore-ma', component: Ch11ZscoreMaComponent },
      { path: 'chapter11/ch11-zscore-rsi', component: Ch11ZscoreRsiComponent },
      { path: 'chapter11/ch11-zscore-macd', component: Ch11ZscoreMacdComponent },
      { path: 'chapter11/ch11-zscore-ppo', component: Ch11ZscorePpoComponent },
      { path: 'chapter11/ch11-pnl-crossover', component: Ch11PnlCrossoverComponent },
      { path: 'chapter11/ch11-pnl-zscore', component: Ch11PnlZscoreComponent },
      { path: '', component: HomeComponent, pathMatch: 'full' }
    ])    
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [StockDeleteDialogComponent]
})
export class AppModule { }
