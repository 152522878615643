import { Component, OnInit } from '@angular/core';
import { Ch10Service } from '../../models/ch10.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch10-zc-direct',
  templateUrl: './ch10-zc-direct.component.html',
  styleUrls: ['./ch10-zc-direct.component.css']
})
export class Ch10ZcDirectComponent implements OnInit {
  zcResult: any;
  zcCols: any;
  private helper: ModelHelper;

  constructor(private service: Ch10Service) { }

  ngOnInit() {
    this.getZcResult();
  }

  getZcResult() {
    this.zcResult = null;
    this.service.getZeroCouponDirect().subscribe(result => {
      this.zcResult = result.result;
      this.helper = new ModelHelper();
      this.zcCols = this.helper.setColDefs(result.result, 170);
    });
  }
}
