import { Component, OnInit } from '@angular/core';
import { Ch7Service } from '../../models/ch7.service';

@Component({
  selector: 'app-ch7-ind-bbands',
  templateUrl: './ch7-ind-bbands.component.html',
  styleUrls: ['./ch7-ind-bbands.component.css']
})
export class Ch7IndBbandsComponent implements OnInit {
  ticker = 'IBM';
  start: string = '2018-01-01';
  end: string = '2018-12-31';
  period: number = 10;
  maType: string = 'sma';
  devUp: number = 2;
  devDown: number = 2;

  constructor(private reporsitory: Ch7Service) { }

  ngOnInit() {
    this.getIndicator(this.ticker, this.start, this.end, this.period, this.maType, this.devUp, this.devDown);
  }

  get indicator(): any {
    return this.reporsitory.bbands;
  }

  getIndicator(ticker: string, start: string, end: string, period: number, maType, devUp: number, devDown: number) {
    this.reporsitory.getBbands(ticker, start, end, period, maType, devUp, devDown);
  }
}
