import { Component, OnInit } from '@angular/core';
import { Ch7Service} from '../../models/ch7.service';

@Component({
  selector: 'app-ch7-ind-aroon',
  templateUrl: './ch7-ind-aroon.component.html',
  styleUrls: ['./ch7-ind-aroon.component.css']
})
export class Ch7IndAroonComponent implements OnInit {
  ticker = 'IBM';
  start: string = '2018-01-01';
  end: string = '2018-12-31';
  period: number = 25;

  constructor(private reporsitory: Ch7Service) { }

  ngOnInit() {
    this.getIndicator(this.ticker, this.start, this.end, this.period);
  }

  get indicator(): any {
    return this.reporsitory.aroon;
  }

  getIndicator(ticker: string, start: string, end: string, period: number) {
    this.reporsitory.getAroon(ticker, start, end, period);
  }
}
