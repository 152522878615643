import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-line',
  templateUrl: './simple-line.component.html',
  styleUrls: ['./simple-line.component.css']
})
export class SimpleLineComponent implements OnInit {
  options: any;

  constructor() { }

  ngOnInit() {
    const data = [];

    for (let i = 0; i < 50; i++) {
      const x = i / 5.0;
      data.push([x, Math.sin(x)]);
    }

    this.options = {
      title: {
        text: 'Simple Line Chart',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      xAxis: {
        type: 'value',
        name: 'X Value',
        nameLocation: 'center',
        nameGap: '30',
        axisLine: { onZero: false }
      },
      yAxis: {
        type: 'value',
        name: 'Y Data',
        nameLocation: 'center',
        nameGap: '50'        
      },
      series: [{
        name: 'line',
        type: 'line',
        data: data
      }],
    };
  }
}
