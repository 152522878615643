import { MarketData } from './market-data.model';

export class StockChartHelper {

  processStockData(stockData: MarketData[]) {
    const categoryData = [];
    const values = [];
    const volume = [];
    for (let i = 0; i < stockData.length; i++) {
      categoryData.push(stockData[i].date.toString().substring(0, 10));
      values.push([stockData[i].open, stockData[i].close, stockData[i].low, stockData[i].high]);
      volume.push(stockData[i].volume);
    }
    return {
      categoryData: categoryData,
      values: values,
      volume: volume
    };
  }

  processCandleData(candles: any) {
    const categoryData = [];
    const values = [];
    const volume = [];
    for (let i = 0; i < candles.length; i++) {
      categoryData.push(candles[i].date.toString().substring(0, 10));
      values.push([candles[i].open, candles[i].close, candles[i].low, candles[i].high]);
      volume.push(candles[i].volume);
    }
    return {
      categoryData: categoryData,
      values: values,
      volume: volume
    };
  }
}
