import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { AvFxData } from '../../models/market-data.model';

@Component({
  selector: 'app-av-fx',
  templateUrl: './av-fx.component.html',
  styleUrls: ['./av-fx.component.css']
})
export class AvFxComponent implements OnInit {
  ticker = 'EURUSD';
  startDate: string = '2018-08-01';
  endDate: string = '2018-11-01';
  period = 'daily';
  key: string;
  displayedCols = ['date', 'open', 'high', 'low', 'close'];

  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.key = localStorage.getItem('avKey');
    if (this.key) {
      if (this.key.length > 6) this.getFx(this.ticker, this.startDate, this.endDate, this.period);
    }
  }

  get fx(): AvFxData[] {
    return this.repository.avFx;
  }

  getFx(ticker: string, start: string, end: string, period: string) {
    this.repository.getAvFx(ticker, start, end, period, this.key);
  }
}
