import { Component, OnInit, OnDestroy } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { MarketData } from '../../models/market-data.model';
import { StockChartHelper } from '../../models/stockChartHelper';

@Component({
  selector: 'app-realtime-stock',
  templateUrl: './realtime-stock.component.html',
  styleUrls: ['./realtime-stock.component.css']
})
export class RealtimeStockComponent implements OnInit, OnDestroy {
  ticker = 'MSFT';
  startDate: string = '2015-01-01';
  endDate: string = '2018-12-31';
  interval: number = 1000;
  points: number = 50;
  private helper: StockChartHelper = new StockChartHelper();
  options: any;
  updateOptions: any;
  private data: any;
  private timer: any;
  constructor(private repository: MarketDataService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    this.data = null;
  }

  get stock(): MarketData[] {
    return this.repository.yahooStock;
  }

  getStock(ticker: string, start: string, end: string) {
    clearInterval(this.timer);
    this.timer = null;
    this.data = null;
    this.repository.getYahooStock(ticker, start, end, 'daily');
  }

  createChart() {

    let stock = this.helper.processStockData(this.stock);
    this.data = {
      categoryData: [stock.categoryData[0]],
      values: [stock.values[0]],
      volume: [stock.volume[0]]
    };
    this.options = this.getOptions();

    let i = 1;
    let subtitle;
    this.timer = setInterval(() => {
      this.data.categoryData.push(stock.categoryData[i]);
      this.data.values.push(stock.values[i]);
      this.data.volume.push(stock.volume[i]);
      if (this.data.values.length > this.points) {
        this.data.categoryData.shift();
        this.data.values.shift();
        this.data.volume.shift();
      }
      subtitle = '(' + (stock.values.length - i) + ' data points left)';
      if (i >= stock.values.length - 1) {
        subtitle = '(finished)';
        clearInterval(this.timer);
      }
      this.updateOptions = {
        title: {
          subtext: subtitle
        },
        xAxis: [
          { data: this.data.categoryData },
          { data: this.data.categoryData }
        ],
        series: [
          { data: this.data.values },
          { data: this.data.volume }
        ]
      };

      i++;
    }, this.interval);
  }

  stopChart() {
    clearInterval(this.timer);
    this.timer = null;
    this.data = null;
  }

  getOptions() {
    var downColor = '#ec0000';
    var downBorderColor = '#8A0000';
    var upColor = '#00da3c';
    var upBorderColor = '#008F28';

    return {
      title: {
        text: this.ticker + ': Stock Price',
        left: 'center',
        top: -5
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      axisPointer: {
        link: { xAxisIndex: 'all' }
      },
      grid: [{
        left: 50,
        right: 20,
        top: '10%',
        height: '60%'
      }, {
        left: 50,
        right: 20,
        height: '20%',
        top: '78%'
      }],
      xAxis: [{
        type: 'category',
        data: this.data.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }, {
        type: 'category',
        gridIndex: 1,
        data: this.data.categoryData,
        scale: true,
        boundaryGap: false,
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { lineStyle: { color: '#777' } },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }
      ],
      yAxis: [{
        scale: true,
        splitArea: {
          show: true
        }
      }, {
        scale: true,
        gridIndex: 1,
        splitNumber: 2,
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false }
      }
      ],
      series: [
        {
          name: 'candlestock',
          type: 'candlestick',
          data: this.data.values,
          itemStyle: {
            color: upColor,
            color0: downColor,
            borderColor: upBorderColor,
            borderColor0: downBorderColor
          }
        },
        {
          name: 'volume',
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          itemStyle: {
            normal: {
              color: '#7fbe9e'
            },
            emphasis: {
              color: '#140'
            }
          },
          data: this.data.volume
        }
      ]
    };
  }

}
