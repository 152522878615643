import { Component, OnInit } from '@angular/core';
import { Ch10Service } from '../../models/ch10.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch10-callable-bond',
  templateUrl: './ch10-callable-bond.component.html',
  styleUrls: ['./ch10-callable-bond.component.css']
})
export class Ch10CallableBondComponent implements OnInit {
  bondResult: any;
  colDefs: any;

  inputCols: any = [
    { headerName: "Parameter", field: "param", width: 100 },
    { headerName: "Value", field: "value", width: 100, editable: true },
  ];
  inputRows: any = [
    { param: 'Eval Date', value: '2007-10-16' },
    { param: 'Issue Date', value: '2004-09-16' },
    { param: 'Call Date', value: '2006-09-15' },
    { param: 'Maturity', value: '2012-09-15' },
    { param: 'Coupon', value: 0.0465 },
    { param: 'Sigma', value: 0.06 },
    { param: 'Reversion a', value: 0.03 },
    { param: 'Rate', value: 0.055 }    
  ];

  private helper: ModelHelper;

  constructor(private service: Ch10Service) { }

  ngOnInit() {
    this.getBondResult(this.inputRows);
  }

  getBondResult(rows: any) {
    this.bondResult = null;
    this.service.getCallableBondPrice(rows[0].value, rows[1].value, rows[2].value, rows[3].value, rows[4].value, rows[5].value,
      rows[6].value, rows[7].value).subscribe(result => {
        this.bondResult = result.result;
        this.helper = new ModelHelper();
        this.colDefs = this.helper.setColDefs(result.result, 120);
      });
  }
}
