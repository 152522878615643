import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Ch8Service {
  private url;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
  }

  mlStockData: any;
  getMlStockData(ticker: string, start: string, end: string, period: number, mlType: string) {
    let url1 = this.url + 'api/mlstockdata/' + ticker + '/' + start + '/' + end + '/' + period + '/' + mlType;
    this.http.get<any>(url1).subscribe(result => { this.mlStockData = result });
  }

  mlProcessedData: any;
  getMlProcessedData(ticker: string, start: string, end: string, period: number, mlType: string, trainDate: string,
    isNormalized: boolean, windowSize: number) {
    let url1 = this.url + 'api/mlprocesseddata/' + ticker + '/' + start + '/' + end + '/' + period + '/' + mlType + '/' +
      trainDate + '/' + isNormalized + '/' + windowSize;
    this.http.get<any>(url1).subscribe(result => { this.mlProcessedData = result });
  }

  mlKnnClass: any;
  getMlKnnClass(ticker: string, start: string, end: string, period: number, trainDate: string,
    isNormalized: boolean, windowSize: number, knum: number) {
    let url1 = this.url + 'api/mlknn/' + ticker + '/' + start + '/' + end + '/' + period + '/' +
      trainDate + '/' + isNormalized + '/' + windowSize + '/' + knum;
    this.http.get<any>(url1).subscribe(result => { this.mlKnnClass = result });
  }

  mlSvmClass: any;
  getMlSvmClass(ticker: string, start: string, end: string, period: number, trainDate: string,
    isNormalized: boolean, windowSize: number) {
    let url1 = this.url + 'api/mlsvm/' + ticker + '/' + start + '/' + end + '/' + period + '/' +
      trainDate + '/' + isNormalized + '/' + windowSize;
    this.http.get<any>(url1).subscribe(result => { this.mlSvmClass = result });
  }

  /*mlSvmRegr: any;
  getMlSvmRegr(ticker: string, start: string, end: string, period: number, trainDate: string,
    isNormalized: boolean, windowSize: number) {
    let url1 = this.url + 'api/mlsvmregr/' + ticker + '/' + start + '/' + end + '/' + period + '/' +
      trainDate + '/' + isNormalized + '/' + windowSize;
    this.http.get<any>(url1).subscribe(result => { this.mlSvmRegr = result });
  }*/

  getMlSvmRegr(ticker: string, start: string, end: string, period: number, trainDate: string,
    isNormalized: boolean, windowSize: number): Observable<any> {
    let url1 = this.url + 'api/mlsvmregr/' + ticker + '/' + start + '/' + end + '/' + period + '/' +
      trainDate + '/' + isNormalized + '/' + windowSize;
    return this.http.get<any>(url1);
  }
}
