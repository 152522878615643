import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class Ch11Service {
  private url;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
  }

  getStrategy2MaCrossover(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod: number, maType: string,
    tradeType: string, tolerance: number) {
    let url1 = this.url + 'api/strategysignal2macrossover/' + ticker + '/' + start + '/' + end + '/' + fastPeriod + '/' + slowPeriod +
      '/' + maType + '/' + tradeType + '/' + tolerance;
    return this.http.get<any>(url1);
  }

  getStrategy2MaCrossoverNatr(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod: number, maType: string,
    natrPeriod: number, natrLevel: number, tradeType: string, tolerance: number) {
    let url1 = this.url + 'api/strategysignal2macrossovernatr/' + ticker + '/' + start + '/' + end + '/' + fastPeriod + '/' + slowPeriod +
      '/' + maType + '/' + natrPeriod + '/' + natrLevel + '/' + tradeType + '/' + tolerance;
    return this.http.get<any>(url1);
  }

  getStrategyMaZscore(ticker: string, start: string, end: string, period: number, maType: string, zEntry: number, zExit: number) {
    let url1 = this.url + 'api/strategysignalmazscore/' + ticker + '/' + start + '/' + end + '/' + period + '/' + maType + '/' + zEntry + '/' + zExit;
    return this.http.get<any>(url1);
  }

  getStrategyRsiZscore(ticker: string, start: string, end: string, period: number, zEntry: number, zExit: number) {
    let url1 = this.url + 'api/strategysignalrsizscore/' + ticker + '/' + start + '/' + end + '/' + period + '/' + zEntry + '/' + zExit;
    return this.http.get<any>(url1);
  }

  getStrategyPpoZscore(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod: number, maType: string,
    zEntry: number, zExit: number) {
    let url1 = this.url + 'api/strategysignalppozscore/' + ticker + '/' + start + '/' + end + '/' + fastPeriod + '/' + slowPeriod + '/' +
      maType + '/' + zEntry + '/' + zExit;
    return this.http.get<any>(url1);
  }

  getStrategyMacdZscore(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod: number, signalPeriod: number,
    zEntry: number, zExit: number) {
    let url1 = this.url + 'api/strategysignalmacdzscore/' + ticker + '/' + start + '/' + end + '/' + fastPeriod + '/' + slowPeriod + '/' +
      signalPeriod + '/' + zEntry + '/' + zExit;
    return this.http.get<any>(url1);
  }

  getPnlCrossover(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod: number, maType: string,
    natrPeriod: number, natrLevel: number, tradeType: string, tolerance: number, notional: number, stopLoss: number,
    protectProfit: number, signalType: string) {
    let url1 = this.url + 'api/pnlcrossover/' + ticker + '/' + start + '/' + end + '/' + fastPeriod + '/' + slowPeriod +
      '/' + maType + '/' + natrPeriod + '/' + natrLevel + '/' + tradeType + '/' + tolerance + '/' + notional + '/' + stopLoss + '/' +
      protectProfit + '/' + signalType;
    return this.http.get<any>(url1);
  }

  getPnlZscore(ticker: string, start: string, end: string, period: number, fastPeriod: number, slowPeriod: number, maType: string,
    zEntry: number, zExit: number, notional: number, stopLoss: number, protectProfit: number, signalType: string) {
    let url1 = this.url + 'api/pnlzscore/' + ticker + '/' + start + '/' + end + '/' + period + '/' + fastPeriod + '/' + slowPeriod + '/' +
      maType + '/' + zEntry + '/' + zExit + '/' + notional + '/' + stopLoss + '/' + protectProfit + '/' + signalType;
    return this.http.get<any>(url1);
  }
}
