import { Component, OnInit } from '@angular/core';
import { Ch9Service } from '../../models/ch9.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch9-option-eu',
  templateUrl: './ch9-option-eu.component.html',
  styleUrls: ['./ch9-option-eu.component.css']
})
export class Ch9OptionEuComponent implements OnInit {
  optionType: string = 'call';
  strike: number = 100;
  spot: number = 100;
  q: number = 0.06;
  r: number = 0.1;
  vol: number = 0.3;
  engineType: string = 'analytic';
  timeSteps: number = 100;
  optionResult: any;
  colDefs: any;
  private helper: ModelHelper;

  constructor(private repository: Ch9Service) { }

  ngOnInit() {
    this.getOptionResult(this.optionType, this.strike, this.spot, this.q, this.r, this.vol, this.engineType, this.timeSteps);
  }
  
  getOptionResult(optionType: string, strike: number, spot: number, q: number, r: number, vol: number,
    engineType: string, timeSteps: number) {
    this.optionResult = null;
    this.repository.getEuropeanOptions(optionType, strike, spot, q, r, vol, engineType, timeSteps).subscribe(result => {
      this.optionResult = result.result;
      this.helper = new ModelHelper();
      this.colDefs = this.helper.setColDefs(this.optionResult, 100);
    });
  }
}
