import { Component, OnInit } from '@angular/core';
import { Ch11Service } from '../../models/ch11.service';
import { StrategyHelper } from '../../models/strategy.helper';

@Component({
  selector: 'app-ch11-crossover-ma',
  templateUrl: './ch11-crossover-ma.component.html',
  styleUrls: ['./ch11-crossover-ma.component.css']
})
export class Ch11CrossoverMaComponent implements OnInit {
  ticker: string = 'SPY';
  start: string = '2016-06-01';
  end: string = '2018-07-01';
  fastPeriod: number = 20;
  slowPeriod: number = 50;
  maType: string = 'sma';
  tradeType: string = 'longshort';
  tolerance: number = 0;
  data: any;
  options: any;
  private helper: StrategyHelper;

  constructor(private service: Ch11Service) { }

  ngOnInit() {
    this.getData(this.ticker, this.start, this.end, this.fastPeriod, this.slowPeriod, this.maType, this.tradeType, this.tolerance);
  }

  getData(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod: number, maType: string,
    tradeType: string, tolerance: number) {
    this.data = null;
    this.helper = new StrategyHelper();
    this.service.getStrategy2MaCrossover(ticker, start, end, fastPeriod, slowPeriod, maType, tradeType, tolerance).subscribe(
      result => {
        this.data = result;
        this.options = this.helper.chartPrice(this.data, true);
      });
  }
}
