import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-directive-test',
  templateUrl: './directive-test.component.html',
  styleUrls: ['./directive-test.component.css']
})
export class DirectiveTestComponent implements OnInit {
  public stocks: Stock[];
  color: string;

  constructor() {    
  }

  ngOnInit() {
    this.stocks = [
      new Stock(1, "AAPL", 207.48, true),
      new Stock(2, "AMZN", 1665.53, false),
      new Stock(3, "GOOG", 1057.79, true),
      new Stock(4, "IBM", 115.67, false),
      new Stock(5, "MSFT", 106.16, true),
    ];
  }

  getStock(key: number): Stock {
    return this.stocks.find(s => s.id == key);
  }

  getStockCount(): number {
    return this.stocks.length;
  }

  getStockColor(key: number) {
    switch (key) {
      case 1: return 'red';
      case 2: return 'green';
      case 3: return 'blue';
      case 4: return 'maroon';
      case 5: return 'black';
    }
  }
}

export class Stock {
  constructor(public id: number, public ticker: string, public price: number, public holdStock: boolean) {
  }
}




