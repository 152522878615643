import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { MarketData } from '../../models/market-data.model';

@Component({
  selector: 'app-chart-stock',
  templateUrl: './chart-stock.component.html',
  styleUrls: ['./chart-stock.component.css']
})
export class ChartStockComponent implements OnInit {
  ticker = 'A';
  startDate: string = '2018-01-01';
  endDate: string = '2018-12-31';
  period = 'daily';

  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.getStock(this.ticker, this.startDate, this.endDate, this.period);
  }

  get stock(): MarketData[] {
    return this.repository.yahooStock;
  }

  getStock(ticker: string, start: string, end: string, period: string) {
    this.repository.getYahooStock(ticker, start, end, period);
  }
}
