import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MarketData } from '../../../models/market-data.model';
import { StockChartHelper } from '../../../models/stockChartHelper'

@Component({
  selector: 'app-candle-chart',
  templateUrl: './candle-chart.component.html',
  styleUrls: ['./candle-chart.component.css']
})
export class CandleChartComponent implements OnInit, OnChanges {
  @Input() stockData: MarketData[];
  private stock: any;
  private helper: StockChartHelper = new StockChartHelper();
  options: any;
  
  constructor() { }

  ngOnInit() {
    this.stock = this.helper.processStockData(this.stockData);
    this.options = this.getOptions();
  }

  ngOnChanges() {
    this.stock = this.helper.processStockData(this.stockData);
    this.options = this.getOptions();
  }

  getOptions() {
    var downColor = '#ec0000';
    var downBorderColor = '#8A0000';
    var upColor = '#00da3c';
    var upBorderColor = '#008F28';

    return {
      title: {
        text: this.stockData[0].ticker + ': Stock Price',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      grid: {
        left: '10%',
        right: '10%',
        bottom: '15%'
      },
      xAxis: {
        type: 'category',
        data: this.stock.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      },
      yAxis: {
        scale: true,
        splitArea: {
          show: true
        }
      },
      dataZoom: [
        {
          show: true,
          type: 'slider',
          y: '90%',
          start: 70,
          end: 100
        }
      ],
      series: [
        {
          type: 'candlestick',
          data: this.stock.values,
          itemStyle: {
            color: upColor,
            color0: downColor,
            borderColor: upBorderColor,
            borderColor0: downBorderColor
          }
        }
      ]
    };
  }
}
