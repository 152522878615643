import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { IsdaRateData } from '../../models/market-data.model';

@Component({
  selector: 'app-isda',
  templateUrl: './isda.component.html',
  styleUrls: ['./isda.component.css']
})
export class IsdaComponent implements OnInit {
  currency: string = 'USD';
  date: string = '20181120';
  displayedCols = ['cur', 'snap', 'mat', 'dayCount', 'fixedDay', 'tenor', 'rate'];

  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.getRate(this.currency, this.date);
  }

  get rate(): IsdaRateData[] {
    return this.repository.isdaRate;
  }

  getRate(currency: string, date: string) {
    this.repository.getIsdaRate(currency, date);
  }
}
