import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../models/repository.service';
import { IndexData } from '../../models/indexData.model';

@Component({
  selector: 'app-index-data',
  templateUrl: './index-data.component.html',
  styleUrls: ['./index-data.component.css']
})
export class IndexDataComponent implements OnInit {
 
  public displayedCols = ['date', 'ig', 'hy', 'spx', 'vix'];

  constructor(private repository: RepositoryService) { }

  ngOnInit() {
  }

  get indexData(): IndexData[] {
    return this.repository.indexData;
  }
}
