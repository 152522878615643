import { Component, OnInit } from '@angular/core';
import { Ch7Service } from '../../models/ch7.service';

@Component({
  selector: 'app-ch7-ind-stoch',
  templateUrl: './ch7-ind-stoch.component.html',
  styleUrls: ['./ch7-ind-stoch.component.css']
})
export class Ch7IndStochComponent implements OnInit {
  ticker = 'IBM';
  start: string = '2018-01-01';
  end: string = '2018-12-31';
  fastkPeriod: number = 14;
  slowkPeriod: number = 3;
  slowdPeriod: number = 3;
  maType: string = 'sma';

  constructor(private reporsitory: Ch7Service) { }

  ngOnInit() {
    this.getIndicator(this.ticker, this.start, this.end, this.fastkPeriod, this.slowkPeriod, this.slowdPeriod, this.maType);

  }

  get indicator(): any {
    return this.reporsitory.stoch;
  }

  getIndicator(ticker: string, start: string, end: string, fastkPeriod: number, slowkPeriod: number, slowdPeriod, maType: string) {
    this.reporsitory.getStoch(ticker, start, end, fastkPeriod, slowkPeriod, slowdPeriod, maType);
  }

}
