import { Component, OnInit } from '@angular/core';
import { Ch9Service } from '../../models/ch9.service';

@Component({
  selector: 'app-ch9-vol-eu',
  templateUrl: './ch9-vol-eu.component.html',
  styleUrls: ['./ch9-vol-eu.component.css']
})
export class Ch9VolEuComponent implements OnInit {
  optionType: string = 'call';
  strike: number = 10.5;
  spot: number = 10;
  q: number = 0.04;
  r: number = 0.1;

  volResult: any = [];
  colDefs = [
    { headerName: "Maturity", field: "maturity", width: 100 },
    { headerName: "Option Price", field: "price", width: 100 },
    { headerName: "Implied Vol", field: "volatility", width: 150 },
  ];

  constructor(private repository: Ch9Service) { }

  ngOnInit() {
    this.getVolResult(this.optionType, this.strike, this.spot, this.q, this.r);
  }

  getVolResult(optionType: string, strike: number, spot: number, q: number, r: number) {
    this.volResult = null;
    this.repository.getEuropeanImpliedVols(optionType, strike, spot, q, r).subscribe(result => {
      this.volResult = result.result;
    });
  }
}
