import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { StockChartHelper } from '../../models/stockChartHelper';

@Component({
  selector: 'app-ch7-ind3-chart',
  templateUrl: './ch7-ind3-chart.component.html',
  styleUrls: ['./ch7-ind3-chart.component.css']
})
export class Ch7Ind3ChartComponent implements OnInit, OnChanges {
  @Input() indicator: any;

  private helper: StockChartHelper = new StockChartHelper();
  private stock: any;
  private legend: any;

  options: any;

  constructor() { }

  ngOnInit() {
    this.stock = this.helper.processCandleData(this.indicator.candles);
    this.options = this.getOptions();
  }

  ngOnChanges() {
    this.stock = this.helper.processCandleData(this.indicator.candles);
    this.options = this.getOptions();
  }


  getOptions() {
    const downColor = '#ec0000';
    const downBorderColor = '#8A0000';
    const upColor = '#00da3c';
    const upBorderColor = '#008F28';

    let candleSeries = {
      name: 'price',
      type: 'candlestick',
      data: this.stock.values,
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: upBorderColor,
        borderColor0: downBorderColor
      }
    };

    let VolumeSeries = {
      name: 'volume',
      type: 'bar',
      xAxisIndex: 2,
      yAxisIndex: 2,
      itemStyle: {
        normal: {
          color: '#7fbe9e'
        },
        emphasis: {
          color: '#140'
        }
      },
      data: this.stock.volume
    }

    let series = [];
    series.push(candleSeries);
    let indSeries = this.processIndicatorSeries();
    for (let i in indSeries) {
      series.push(indSeries[i]);
    }
    series.push(VolumeSeries);

    let leftMargin = 50;
    if (this.indicator.name == 'ad' || this.indicator.name == 'obv') leftMargin = 100;

    return {
      title: {
        text: this.indicator.ticker + ': Stock Price (' + this.indicator.name + ')',
        left: 'center'
      },
      legend: {
        top: '49%',
        right: 30,
        data: this.legend
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      axisPointer: {
        link: { xAxisIndex: 'all' }
      },
      grid: [{
        left: leftMargin,
        right: 20,
        top: '6%',
        height: '37%'
      }, {
          left: leftMargin,
        right: 20,
        height: '25%',
        top: '53%'
        }, {
          left: leftMargin,
          right: 20,
          height: '12%',
          top: '80%'
        }],
      xAxis: [{
        type: 'category',
        data: this.stock.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }, {
          type: 'category',
          gridIndex: 1,
          data: this.stock.categoryData,
          scale: true,
          boundaryGap: false,
          axisLine: { onZero: false },
          splitLine: { show: false },
          splitNumber: 20,
          min: 'dataMin',
          max: 'dataMax'
        },
        {
        type: 'category',
        gridIndex: 2,
        data: this.stock.categoryData,
        scale: true,
        boundaryGap: false,
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { lineStyle: { color: '#777' } },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }
      ],
      yAxis: [{
        scale: true,
        splitArea: {
          show: true
        }
      },
        {
          scale: true,
          gridIndex: 1,
          splitArea: {
            show: true
          }
        },{
        scale: true,
        gridIndex: 2,
        splitNumber: 2,
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false }
      }
      ],
      dataZoom: [
        {
          show: true,
          type: 'slider',
          y: '95%',
          start: 70,
          end: 100,
          xAxisIndex: [0, 1, 2],
        }
      ],
      series: series
    };
  }


  private processIndicatorSeries() {
    let indicatorSeries = [];
    switch (this.indicator.name) {
      case 'aroon':
        this.legend = [, { name: 'aroonDown', icon: 'line' }, { name: 'aroonUp', icon: 'line' }];
        indicatorSeries.push({
          name: 'aroonDown',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.indicator.indicator.aroonDown,
          showSymbol: false
        });
        indicatorSeries.push({
          name: 'aroonUp',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.indicator.indicator.aroonUp,
          showSymbol: false
        });       
        break;
      case 'macd':
        this.legend = [, { name: 'macd', icon: 'line' }, { name: 'signal', icon: 'line' }, { name: 'histogram', icon: 'rect' }];
        indicatorSeries.push({
          name: 'macd',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.indicator.indicator.macd,
          showSymbol: false
        });
        indicatorSeries.push({
          name: 'signal',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.indicator.indicator.signal,
          showSymbol: false
        });
        indicatorSeries.push({
          name: 'histogram',
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.indicator.indicator.hist,
          itemStyle: {
            normal: {
              color: '#7fbe9e'
            },
            emphasis: {
              color: '#140'
            }
          }
        });       
        break;
      case 'stoch':
        this.legend = [, { name: 'slowK', icon: 'line' }, { name: 'slowD', icon: 'line' }];
        indicatorSeries.push({
          name: 'slowK',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.indicator.indicator.slowK,
          showSymbol: false
        });
        indicatorSeries.push({
          name: 'slowD',
          type: 'line',
          xAxisIndex: 1,
          yAxisIndex: 1,
          data: this.indicator.indicator.slowD,
          showSymbol: false
        });
        break;
      default:
        this.legend = [, { name: this.indicator.name, icon: 'line' }];
        indicatorSeries.push({
          name: this.indicator.name,
          type: 'line',
          data: this.indicator.indicator,
          xAxisIndex: 1,
          yAxisIndex: 1,
          showSymbol: false
        });
        break;
    }
    return indicatorSeries;
  }

}

