import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { MarketData } from '../../models/market-data.model';

@Component({
  selector: 'app-iex-stock',
  templateUrl: './iex-stock.component.html',
  styleUrls: ['./iex-stock.component.css']
})
export class IexStockComponent implements OnInit {
  ticker = 'A';
  range = 'oneMonth';
  displayedCols = ['date', 'open', 'high', 'low', 'close', 'volume'];

  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.getStock(this.ticker, this.range);
  }

  get stock(): MarketData[] {
    return this.repository.iexStock;
  }

  getStock(ticker: string, range: string) {
    this.repository.getIexStock(ticker, range);
  }
}
