export class StrategyHelper {
  chartPnl(data: any) {
    const categoryData = [];
    const pnlData = [];
    const pnlData1 = [];
    const positionData = [];

    data.pnl.forEach(function (d) {
      categoryData.push(d.signal.date.toString().substring(0, 10));
      pnlData.push(d.pnLCum);
      pnlData1.push(d.pnLCum1);
      positionData.push(d.signal.position);
    });

    return {
      title: {
        text: data.name,
        left: 'center'
      },
      legend: {
        top: 20,
        right: 45,
        data: [{ name: 'PnL for Strategy', icon: 'line' }, { name: 'PnL for Buy-Hold', icon: 'line' },]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      axisPointer: {
        link: { xAxisIndex: 'all' }
      },
      grid: [{
        left: 50,
        right: 40,
        top: '7%',
        height: '46%'
      }, {
        left: 50,
        right: 40,
        height: '25%',
        top: '63%'
      }],
      xAxis: [{
        type: 'category',
        data: categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }, {
        type: 'category',
        gridIndex: 1,
        data: categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }],
      yAxis: [{
        scale: true,
        name: 'PnL',
        splitArea: {
          show: true
        }
      },
      {
        scale: true,
        name: 'Trading Position',
        gridIndex: 1,
        splitArea: {
          show: true
        }
      }
      ],
      dataZoom: {
        show: true,
        type: 'slider',
        y: '93%',
        start: 70,
        end: 100,
        xAxisIndex: [0, 1],
      },
      series: [
        {
          name: 'PnL for Strategy',
          type: 'line',
          data: pnlData,
          showSymbol: false
        },
        {
          name: 'PnL for Buy-Hold',
          type: 'line',
          data: pnlData1,
          showSymbol: false
        },
        {
          name: 'Trading Position',
          type: 'line',
          step: 'start',
          data: positionData,
          xAxisIndex: 1,
          yAxisIndex: 1,
          showSymbol: false,
          lineStyle: { color: 'steelblue' }
        }
      ]
    }
  }


  chartPrice2(data: any, isMaSeries, indicatorIndex: number, marks: any) {
    let stock = this.processCandleData(data.signal);
    let series = [];
    let showLegend = false;
    let candleSeries = this.processCandlestickSeries(stock);
    series.push(candleSeries);
    if (isMaSeries) {
      let maSeries = this.process2MaSeries(data);
      for (let i in maSeries) {
        series.push(maSeries[i]);
      }
      showLegend = true;
    }
    let signalSeries = this.processSignalSeries(data, indicatorIndex, marks);
    series.push(signalSeries);
    let positionSeries = this.processTradePositionSeries(data, 2);
    series.push(positionSeries);

    let yname = data.indicatorNames[indicatorIndex];
    if (marks.length == 2) yname = data.indicatorNames[1] + '-' + data.indicatorNames[0];

    return {
      title: {
        text: data.name,
        left: 'center'
      },
      legend: {
        show: showLegend,
        top: 20,
        right: 45,
        data: ['price', { name: 'fastMa', icon: 'line' }, { name: 'slowMa', icon: 'line' }]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      axisPointer: {
        link: { xAxisIndex: 'all' }
      },
      grid: [{
        left: 50,
        right: 40,
        top: '6%',
        height: '27%'
      }, {
        left: 50,
        right: 40,
        height: '23%',
        top: '42%'
      }, {
        left: 50,
        right: 40,
        height: '13%',
        top: '75%'
      }
      ],
      xAxis: [{
        type: 'category',
        data: stock.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }, {
        type: 'category',
        gridIndex: 1,
        data: stock.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }, {
        type: 'category',
        gridIndex: 2,
        data: stock.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }
      ],
      yAxis: [{
        scale: true,
        name: 'Price',
        splitArea: {
          show: true
        }
      },
      {
        scale: true,
        name: yname,
        gridIndex: 1,
        splitArea: {
          show: true
        }
      },
      {
        scale: true,
        name: 'Trading Position',
        gridIndex: 2,
        splitArea: {
          show: true
        }
      }
      ],
      dataZoom: {
        show: true,
        type: 'slider',
        y: '93%',
        start: 70,
        end: 100,
        xAxisIndex: [0, 1, 2],
      },
      series: series
    }
  }

  chartPrice(data: any, isMaSeries) {
    let stock = this.processCandleData(data.signal);
    let series = [];
    let showLegend = false;
    let candleSeries = this.processCandlestickSeries(stock);
    series.push(candleSeries);
    if (isMaSeries) {
      let maSeries = this.process2MaSeries(data);
      for (let i in maSeries) {
        series.push(maSeries[i]);
      }
      showLegend = true;
    }
    let positionSeries = this.processTradePositionSeries(data, 1);
    series.push(positionSeries);

    return {
      title: {
        text: data.name,
        left: 'center'
      },
      legend: {
        show: showLegend,
        top: 55,
        right: 45,
        data: ['price', { name: 'fastMa', icon: 'line' }, { name: 'slowMa', icon: 'line' }]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      axisPointer: {
        link: { xAxisIndex: 'all' }
      },
      grid: [{
        left: 50,
        right: 40,
        top: '7%',
        height: '46%'
      }, {
        left: 50,
        right: 40,
        height: '25%',
        top: '63%'
      }],
      xAxis: [{
        type: 'category',
        data: stock.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }, {
        type: 'category',
        gridIndex: 1,
        data: stock.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }],
      yAxis: [{
        scale: true,
        name: 'Price',
        splitArea: {
          show: true
        }
      },
      {
        scale: true,
        name: 'Trading Position',
        gridIndex: 1,
        splitArea: {
          show: true
        }
      }
      ],
      dataZoom: {
        show: true,
        type: 'slider',
        y: '93%',
        start: 70,
        end: 100,
        xAxisIndex: [0, 1],
      },
      series: series
    }
  }


  private processCandlestickSeries(stock: any) {    
    const downColor = '#ec0000';
    const downBorderColor = '#8A0000';
    const upColor = '#00da3c';
    const upBorderColor = '#008F28'

    return {
      name: 'price',
      type: 'candlestick',
      data: stock.values,
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: upBorderColor,
        borderColor0: downBorderColor
      }
    };
  }

  private processSignalSeries(data: any, IndicatorIndex: number, marks: any) {
    let dat = [];
    data.signal.forEach(function (d) {
      dat.push(d.indicators[IndicatorIndex]);
    });
    let markLine = {};
    if (marks) {
      if (marks.length == 1) {
        markLine = {
          label: 'NATR',
          data: [{
            name: 'level', yAxis: marks[0],
            lineStyle: { normal: { color: "red" } }
          }]
        }
      } else if (marks.length == 2) {
        markLine = {
          label: 'Z-Score',
          data: [{
            name: 'zEntry', yAxis: marks[0],
            lineStyle: { normal: { color: "red" } }
          }, {
              name: '-zEntry', yAxis: -marks[0],
              lineStyle: { normal: { color: "red" } }
            },
            {
              name: 'zExit', yAxis: marks[1],
              lineStyle: { normal: { color: "green" } }
            }, {
              name: '-zExit', yAxis: -marks[1],
              lineStyle: { normal: { color: "green" } }
            }
          ]
        }
      }
    }
    return {
      name: data.indicatorNames[IndicatorIndex],
      type: 'line',
      data: dat,
      xAxisIndex: 1,
      yAxisIndex: 1,
      showSymbol: false,
      lineStyle: { color: 'steelblue' },
      markLine: markLine
    }
  }

  private processTradePositionSeries(data: any, axisIndex) {
    let dat = [];
    data.signal.forEach(function (d) {
      dat.push(d.position);
    });
    return {
      name: 'trade position',
      type: 'line',
      step: 'start',
      data: dat,
      xAxisIndex: axisIndex,
      yAxisIndex: axisIndex,
      showSymbol: false,
      lineStyle: { color: 'steelblue' }
    }
  }

  private process2MaSeries(data: any) {
    let fastData = [];
    let slowData = [];
    let indicatorSeries = [];

    data.signal.forEach(function (d) {
      fastData.push(d.indicators[0]);
      slowData.push(d.indicators[1]);
    });

    indicatorSeries.push(
      {
        name: 'fastMa',
        type: 'line',
        data: fastData,
        showSymbol: false
      });
    indicatorSeries.push(
      {
        name: 'slowMa',
        type: 'line',
        data: slowData,
        showSymbol: false
      });

    return indicatorSeries;
  }

  private processCandleData(candles: any) {
    const categoryData = [];
    const values = [];
    const volume = [];
    for (let i = 0; i < candles.length; i++) {
      categoryData.push(candles[i].date.toString().substring(0, 10));
      values.push([candles[i].open, candles[i].close, candles[i].low, candles[i].high]);
      volume.push(candles[i].volume);
    }
    return {
      categoryData: categoryData,
      values: values,
      volume: volume
    };
  }
}
