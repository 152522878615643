import { Component, OnInit } from '@angular/core';
import { SlrOutput } from '../../models/ch6.model';
import { Ch6Service } from '../../models/ch6.service'

@Component({
  selector: 'app-ch6-pca2d-stock',
  templateUrl: './ch6-pca2d-stock.component.html',
  styleUrls: ['./ch6-pca2d-stock.component.css']
})
export class Ch6Pca2dStockComponent implements OnInit {
  title: string = '2D PCA  Prices';
  titleReturn: string = '2D PCA Returns';
  ticker1: string = '^GSPC';
  ticker2: string = 'C';
  start: string = '2013-01-01';
  end: string = '2018-01-01';

  constructor(private repository: Ch6Service) { }

  ngOnInit() {
    this.getPca2dOutput(this.ticker1, this.ticker2, this.start, this.end);
  }

  get pca2dOutput(): SlrOutput {
    return this.repository.pca2dStock;
  }

  get pca2dOutputReturn(): SlrOutput {
    return this.repository.pca2dStockReturn;
  }

  getPca2dOutput(ticker1: string, ticker2: string, start: string, end: string) {
    this.repository.getPca2dStock(ticker1, ticker2, start, end);
    this.repository.getPca2dStockReturn(ticker1, ticker2, start, end);
  }
}
