import { Component, OnInit } from '@angular/core';
import { Ch9Service } from '../../models/ch9.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch9-option-realworld',
  templateUrl: './ch9-option-realworld.component.html',
  styleUrls: ['./ch9-option-realworld.component.css']
})
export class Ch9OptionRealworldComponent implements OnInit {
  optionResult: any;
  engineType: string = 'binomialJarrowRudd';
  colDefs: any;
  private helper: ModelHelper;

  constructor(private repository: Ch9Service) { }

  ngOnInit() {
    this.getOptionResult(this.engineType);
  }

  getOptionResult(engineType: string) {
    this.optionResult = null;
    this.repository.getAmericanOptionRealWorld(engineType).subscribe(result => {
      this.optionResult = result.result;
      this.helper = new ModelHelper();
      this.colDefs = this.helper.setColDefs(result.result, 100);
    });
  }
}
