import { Component, OnInit } from '@angular/core';
import { Ch11Service } from '../../models/ch11.service';
import { StrategyHelper } from '../../models/strategy.helper';

@Component({
  selector: 'app-ch11-pnl-crossover',
  templateUrl: './ch11-pnl-crossover.component.html',
  styleUrls: ['./ch11-pnl-crossover.component.css']
})
export class Ch11PnlCrossoverComponent implements OnInit {
  ticker: string = 'SPY';
  start: string = '2017-01-01';
  end: string = '2018-12-31';
  fastPeriod: number = 30;
  slowPeriod: number = 70;
  maType: string = 'sma';
  natrPeriod: number = 14;
  natrLevel: number = 0.75;
  tradeType: string = 'long';
  tolerance: number = 0;
  notional: number = 10000;
  stopLoss: number = 0.1;
  protectProfit: number = 0.3;
  signalType: string = 'ma';
  data: any;
  options: any;
  private helper: StrategyHelper;

  colDefs: any = [
    { headerName: "Strategy", field: "strategy", width: 150 },
    { headerName: "P&L", field: "pnl", width: 100  },
    { headerName: "# of Trades", field: "numTrades", width: 100  },
    { headerName: "Sharpe Ratio", field: "sharpe", width: 110  },
    { headerName: "Max Drawdown", field: "drawdown", width: 130  },
  ];
  rowDefs: any;

  constructor(private service: Ch11Service) { }
   
  ngOnInit() {
    this.getData(this.ticker, this.start, this.end, this.fastPeriod, this.slowPeriod, this.maType, this.natrPeriod, this.natrLevel,
      this.tradeType, this.tolerance, this.notional, this.stopLoss, this.protectProfit, this.signalType);
  }

  getData(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod: number, maType: string, natrPeriod: number, natrLevel: number,
    tradeType: string, tolerance: number, notional: number, stopLoss: number, protectProfit: number, signalType: string) {
    this.data = null;
    this.helper = new StrategyHelper();
    this.rowDefs = [];
    let strategy = "MA Crossover";
    if (signalType == 'natr') strategy = 'MA Corssover + NATR';
    this.service.getPnlCrossover(ticker, start, end, fastPeriod, slowPeriod, maType, natrPeriod, natrLevel, tradeType, tolerance,
      notional, stopLoss, protectProfit, signalType).subscribe(result => {
        this.data = result;
        let risk = result.risk;
        this.options = this.helper.chartPnl(this.data);
        this.rowDefs.push({
          strategy: strategy, pnl: risk.pnL.toFixed(0), numTrades: risk.numberTrades,
          sharpe: risk.sharpe, drawdown: risk.maxDrawdown.toFixed(2)
        });
        this.rowDefs.push({
          strategy: 'Buy-Hold', pnl: risk.pnL1.toFixed(0), numTrades: 1,
          sharpe: risk.sharpe1, drawdown: risk.maxDrawdown1.toFixed(2)
        });
      });
  }
}
