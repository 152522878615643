import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { AvQuote } from '../../models/market-data.model';

@Component({
  selector: 'app-av-quote',
  templateUrl: './av-quote.component.html',
  styleUrls: ['./av-quote.component.css']
})
export class AvQuoteComponent implements OnInit {
  ticker = 'A';
  key: string;

  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.key = localStorage.getItem('avKey');
    if (this.key) {
      if (this.key.length > 6) this.getQuote(this.ticker);
    }
  }

  get quote(): AvQuote {
    return this.repository.avQuote;
  }

  getQuote(ticker: string) {
    this.repository.getAvQuote(ticker, this.key);
  }
}
