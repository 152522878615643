import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-ch8-regr-chart',
  templateUrl: './ch8-regr-chart.component.html',
  styleUrls: ['./ch8-regr-chart.component.css']
})
export class Ch8RegrChartComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() mlData: any;
  options: any;

  constructor() { }

  ngOnInit() {
    this.options = this.getOptions(this.mlData, this.title);
  }

  ngOnChanges() {
    this.options = this.getOptions(this.mlData, this.title);
  }

  getOptions(mlData: any, title: string) {
    let data1 = [];
    let data2 = [];
    mlData.forEach(function (d) {
      data1.push([d.date, d.expected]);
      data2.push([d.date, d.predicted]);
    });

    return {
      legend: {
        top: '8%',
        right: 20
      },
      grid: {
        left: '15%',
        right: '5%',
        bottom: 50
      },
      title: {
        text: title,
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        backgroundColor: 'rgba(120, 120, 120, 0.9)',
        borderWidth: 1,
        borderColor: '#aaa',
        padding: 10,
        textStyle: {
          color: 'white'
        }
      },
      xAxis: {
        type: 'category',
        name: 'Date',
        nameLocation: 'center',
        nameGap: 30,
        boundaryGap: false,
      },
      yAxis: {
        type: 'value',
        name: 'Stock Price',
        nameLocation: 'center',
        nameGap: 40,
        scale: true,
      },
      series: [{
        name: 'Expected',
        type: 'scatter',
        data: data1,
        symbol: 'circle',
        symbolSize: 6,
        zlevel: 0
      },
      {
        name: 'Predicted',
        type: 'line',
        data: data2,
        showSymbol: false,
        zlevel: 10
      }
      ]
    };
  }
}
