import { Component, OnInit, Input, OnChanges  } from '@angular/core';

@Component({
  selector: 'app-ch10-line-chart3',
  templateUrl: './ch10-line-chart3.component.html',
  styleUrls: ['./ch10-line-chart3.component.css']
})
export class Ch10LineChart3Component implements OnInit, OnChanges {
  @Input() data: any;
  hazardOptions: any;
  survivalOptions: any;
  constructor() { }

  ngOnInit() {
    this.hazardOptions = this.getOptions(this.data, 'hazard');
    this.survivalOptions = this.getOptions(this.data, 'survival');
  }

  ngOnChanges() {
    this.hazardOptions = this.getOptions(this.data, 'hazard');
    this.survivalOptions = this.getOptions(this.data, 'survival');
  }

  getOptions(data: any, chartType: string) {
    let title = 'Hazard Rate';
    let ylabel = 'Hazard Rate (%)';
    let data1 = [];
    if (chartType == 'hazard') {
      data.forEach(function (d) {
        data1.push([d['timesToMaturity'], d['hazard Rate (%)']]);
      });
    } else if (chartType == 'survival') {
      title = 'Survival Probability';
      ylabel = 'Survival Probability (%)';
      data.forEach(function (d) {
        data1.push([d['timesToMaturity'], d['survival Probability (%)']]);
      });
    } 

    return {
      title: {
        text: title,
        left: 'center'
      },
      grid: {
        top: 35,
        left: 55,
        right: 10
      },
      xAxis: {
        type: 'value',
        name: 'Maturity (Years)',
        nameLocation: 'center',
        nameGap: '30',
        axisLine: { onZero: false }
      },
      yAxis: {
        type: 'value',
        scale: true,
        name: ylabel,
        nameLocation: 'center',
        nameGap: '35'
      },
      series: [{
        name: 'Discount Rate',
        type: 'line',
        data: data1,
        showSymbol: false
      }]
    };
  }


}
