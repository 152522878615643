import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { MarketData } from '../../models/market-data.model';

@Component({
  selector: 'app-av-bar',
  templateUrl: './av-bar.component.html',
  styleUrls: ['./av-bar.component.css']
})
export class AvBarComponent implements OnInit {
  ticker: string = 'A';
  interval: string = "5";
  outputsize: string = "1"
  displayedCols = ['date', 'open', 'high', 'low', 'close', 'volume'];
  key: string;

  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.key = localStorage.getItem('avKey');
    if (this.key) {
      if (this.key.length > 6) this.getBar(this.ticker, this.interval, this.outputsize);
    }
  }

  get bar(): MarketData[] {
    return this.repository.avStockBar;
  }

  getBar(ticker: string, interval: string, outputsize: string) {
    this.repository.getAvStockBar(ticker, interval, outputsize, this.key);
  }
}
