import { Component, OnInit } from '@angular/core';
import { MlrOutput } from '../../models/ch6.model';
import { Ch6Service } from '../../models/ch6.service'

@Component({
  selector: 'app-ch6-pca-index',
  templateUrl: './ch6-pca-index.component.html',
  styleUrls: ['./ch6-pca-index.component.css']
})
export class Ch6PcaIndexComponent implements OnInit {
  title: string = 'PCA';

  constructor(private repository: Ch6Service) { }

  ngOnInit() {
    this.getPcaOutput();
  }

  get pcaOutput(): MlrOutput {
    return this.repository.pcaIndex;
  }

  getPcaOutput() {
    this.repository.getPcaIndex();
  }
}
