import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../models/repository.service';
import { MarketDataService } from '../../models/market-data.service';
import { MarketData } from '../../models/market-data.model';

@Component({
  selector: 'app-yahoo-stock-save',
  templateUrl: './yahoo-stock-save.component.html',
  styleUrls: ['./yahoo-stock-save.component.css']
})
export class YahooStockSaveComponent implements OnInit {
  ticker = 'A';
  startDate: string = '2017-11-01';
  endDate: string = '2018-11-01';
  period = 'daily';
  displayedCols = ['date', 'open', 'high', 'low', 'close', 'closeAdj', 'volume'];
  isSaved = false;

  constructor(private marketRepo: MarketDataService, private repo: RepositoryService) { }

  ngOnInit() {
    this.getStock(this.ticker, this.startDate, this.endDate, this.period);
  }

  get stock(): MarketData[] {
    return this.marketRepo.yahooStock;
  }

  getStock(ticker: string, start: string, end: string, period: string) {
    this.isSaved = false;
    this.marketRepo.getYahooStock(ticker, start, end, period);
  }

  get errorMsg() {
    return this.repo.errorMsg;
  }

  saveStock() {
    this.repo.addStockPrice(this.stock);
    this.isSaved = true;
  }
}
