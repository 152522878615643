import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-ch8-class-res',
  templateUrl: './ch8-class-res.component.html',
  styleUrls: ['./ch8-class-res.component.css']
})
export class Ch8ClassResComponent implements OnInit {
  @Input() mlResult

  colDefs = [
    { headerName: "Date", field: "date", width: 100 },
    { headerName: "Expect", field: "expected", width: 100 },
    { headerName: "Predict", field: "predicted", width: 100 },
  ];

  constructor() { }

  ngOnInit() {
  }
}
