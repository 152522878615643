import { Component, OnInit } from '@angular/core';
import { Ch7Service } from '../../models/ch7.service';

@Component({
  selector: 'app-ch7-ind-ma',
  templateUrl: './ch7-ind-ma.component.html',
  styleUrls: ['./ch7-ind-ma.component.css']
})
export class Ch7IndMaComponent implements OnInit {
  ticker = 'IBM';
  start: string = '2018-01-01';
  end: string = '2018-12-31';
  period: number = 10;
  maType: string = 'sma';

  constructor(private reporsitory: Ch7Service) { }

  ngOnInit() {
    this.getIndicator(this.ticker, this.start, this.end, this.period, this.maType);
   
  }

  get indicator(): any {
    return this.reporsitory.ma;
  }

  getIndicator(ticker: string, start: string, end: string, period: number, maType: string) {
    this.reporsitory.getMa(ticker, start, end, period, maType);
  }
}
