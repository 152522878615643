import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-way-binding',
  templateUrl: './two-way-binding.component.html',
  styleUrls: ['./two-way-binding.component.css']
})
export class TwoWayBindingComponent implements OnInit {
  public ticker: string = 'IBM';
  public date: Date = new Date('7/14/2015');
  public open: number = 169.43;
  public high: number = 169.54;
  public low: number = 168.24;
  public close: number = 168.61;

  constructor() { }

  ngOnInit() {
  }  

  getColor(price: number): string {
    return price < 169 ? 'red' : 'green';
  }
}
