import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MarketData } from '../../../models/market-data.model';
import { StockChartHelper } from '../../../models/stockChartHelper';

@Component({
  selector: 'app-candle-volume',
  templateUrl: './candle-volume.component.html',
  styleUrls: ['./candle-volume.component.css']
})
export class CandleVolumeComponent implements OnInit, OnChanges {
  @Input() stockData: MarketData[];
  private stock: any;
  private helper: StockChartHelper = new StockChartHelper();
  options: any;

  constructor() { }

  ngOnInit() {
    this.stock = this.helper.processStockData(this.stockData);
    this.options = this.getOptions();
  }

  ngOnChanges() {
    this.stock = this.helper.processStockData(this.stockData);
    this.options = this.getOptions();
  }

  getOptions() {
    var downColor = '#ec0000';
    var downBorderColor = '#8A0000';
    var upColor = '#00da3c';
    var upBorderColor = '#008F28';

    return {
      title: {
        text: this.stockData[0].ticker + ': Stock Price',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      axisPointer: {
        link: { xAxisIndex: 'all' }
      },
      grid: [{
        left: 50,
        right: 20,
        top: '10%',
        height: '50%'
      }, {
        left: 50,
        right: 20,
        height: '20%',
        top: '65%'
      }],
      xAxis: [{
        type: 'category',
        data: this.stock.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }, {
        type: 'category',
        gridIndex: 1,
        data: this.stock.categoryData,
        scale: true,
        boundaryGap: false,
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { lineStyle: { color: '#777' } },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }
      ],
      yAxis: [{
        scale: true,
        splitArea: {
          show: true
        }
      }, {
        scale: true,
        gridIndex: 1,
        splitNumber: 2,
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false }
      }
      ],
      dataZoom: [
        {
          show: true,
          type: 'slider',
          y: '90%',
          start: 70,
          end: 100,
          xAxisIndex: [0, 1],
        }
      ],
      series: [
        {
          name: 'candlestick',
          type: 'candlestick',
          data: this.stock.values,
          itemStyle: {
            color: upColor,
            color0: downColor,
            borderColor: upBorderColor,
            borderColor0: downBorderColor
          }
        },
        {
          name: 'volume',
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          itemStyle: {
            normal: {
              color: '#7fbe9e'
            },
            emphasis: {
              color: '#140'
            }
          },
          data: this.stock.volume
        }
      ]
    };
  }
}
