import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart3d',
  templateUrl: './chart3d.component.html',
  styleUrls: ['./chart3d.component.css']
})
export class Chart3dComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {
  }
}
