import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../models/repository.service';
import { Symbol } from '../../models/symbol.model';

@Component({
  selector: 'app-all-stocks',
  templateUrl: './all-stocks.component.html',
  styleUrls: ['./all-stocks.component.css']
})
export class AllStocksComponent implements OnInit {
  public displayedCols = ['id', 'ticker', 'region', 'sector'];

  constructor(private repository: RepositoryService) { }

  ngOnInit() {
  }

  get stocks(): Symbol[] {
    return this.repository.stocks;
  }
}
