import { Injectable} from '@angular/core';
import { HubConnection, HubConnectionBuilder, IStreamResult } from '@aspnet/signalr';
import { AnnInputModel } from '../models/ml.model';

@Injectable({
  providedIn: 'root'
})
export class Ch5HubService {  
  private connection: HubConnection;

  constructor() {
    this.connection = new HubConnectionBuilder().withUrl('/ch5Hub').build();
    this.connection.serverTimeoutInMilliseconds = 1000 * 60 * 10; //100 minutes
    this.connection.start();
  }

  startStream(ticker: string, start: string, end: string, interval: number): IStreamResult<any> {
    if (this.connection.state == 0) this.connection.start();
    return this.connection.stream("SendStock", ticker, start, end, interval);
  }
}

@Injectable({
  providedIn: 'root'
})
export class Ch8AnnClassHubService {
  private connection: HubConnection;

  constructor() {
    this.connection = new HubConnectionBuilder().withUrl('/ch8Hub').build();
    this.connection.serverTimeoutInMilliseconds = 1000 * 60 * 10; //100 minutes
    this.connection.start();
  }

  startStream(model: AnnInputModel): IStreamResult<any> {
    if (this.connection.state == 0) this.connection.start();
    return this.connection.stream("SendAnnClassResult", model);
  }
}

@Injectable({
  providedIn: 'root'
})
export class Ch8AnnRegrHubService {
  private connection: HubConnection;

  constructor() {
    this.connection = new HubConnectionBuilder().withUrl('/ch8Hub').build();
    this.connection.serverTimeoutInMilliseconds = 1000 * 60 * 10; //100 minutes
    this.connection.start();
  }

  startStream(model: AnnInputModel): IStreamResult<any> {
    if (this.connection.state == 0) this.connection.start();
    return this.connection.stream("SendAnnRegrResult", model);
  }
}
