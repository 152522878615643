import { Component, OnInit } from '@angular/core';
import { Ch5HubService } from '../../models/signalr.service';

@Component({
  selector: 'app-realtime-server',
  templateUrl: './realtime-server.component.html',
  styleUrls: ['./realtime-server.component.css']
})
export class RealtimeServerComponent implements OnInit{
  ticker: string = 'MSFT';
  startDate: string = '2015-01-01';
  endDate: string = '2018-12-31';
  interval: number = 1000;
  points: number = 50;
  options: any;
  updateOptions: any;
  private data: any;
  private subscription: any;

  constructor(private service: Ch5HubService) { }
  

  ngOnInit() {    
  }

  getChart(ticker: string, start: string, end: string, interval: number) {
    const categoryData = [];
    const values = [];
    const volume = [];
    this.data = {
      categoryData: categoryData,
      values: values,
      volume: volume
    };

    this.options = this.getOptions();

    let i = 1;
    this.subscription = this.service.startStream(ticker, start, end, interval).subscribe({
      next: (item) => {
        if (item.status == 'starting') {
          this.data.categoryData.push(item.stock.date.toString().substring(0, 10));
          this.data.values.push([item.stock.open, item.stock.close, item.stock.low, item.stock.high]);
          this.data.volume.push(item.stock.volume);
          if (this.data.categoryData.length > this.points) {
            this.data.categoryData.shift();
            this.data.values.shift();
            this.data.volume.shift();
          }
         
          let subtitle = '(' + (item.totalDataPoints - i) + ' data points left)';
          if (item.status == 'finished') {
            subtitle = '(finished)';
            this.subscription.dispose();
          }
          this.updateOptions = {
            title: {
              subtext: subtitle
            },
            xAxis: [
              { data: this.data.categoryData },
              { data: this.data.categoryData }
            ],
            series: [
              { data: this.data.values },
              { data: this.data.volume }
            ]
          };

          i++;
        }
      },
      complete: () => {
        console.log("finished.");
      },
      error: (err) => {
        console.log(err);
      }
    });

  }

  getOptions() {
    var downColor = '#ec0000';
    var downBorderColor = '#8A0000';
    var upColor = '#00da3c';
    var upBorderColor = '#008F28';

    return {
      title: {
        text: this.ticker + ': Stock Price',
        left: 'center',
        top: -5
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      axisPointer: {
        link: { xAxisIndex: 'all' }
      },
      grid: [{
        left: 50,
        right: 20,
        top: '10%',
        height: '60%'
      }, {
        left: 50,
        right: 20,
        height: '20%',
        top: '78%'
      }],
      xAxis: [{
        type: 'category',
        data: this.data.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }, {
        type: 'category',
        gridIndex: 1,
        data: this.data.categoryData,
        scale: true,
        boundaryGap: false,
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { lineStyle: { color: '#777' } },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }
      ],
      yAxis: [{
        scale: true,
        splitArea: {
          show: true
        }
      }, {
        scale: true,
        gridIndex: 1,
        splitNumber: 2,
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false }
      }
      ],
      series: [
        {
          name: 'candlestock',
          type: 'candlestick',
          data: this.data.values,
          itemStyle: {
            color: upColor,
            color0: downColor,
            borderColor: upBorderColor,
            borderColor0: downBorderColor
          }
        },
        {
          name: 'volume',
          type: 'bar',
          xAxisIndex: 1,
          yAxisIndex: 1,
          itemStyle: {
            normal: {
              color: '#7fbe9e'
            },
            emphasis: {
              color: '#140'
            }
          },
          data: this.data.volume
        }
      ]
    };
  }

  stopChart() {
    this.subscription.dispose();
  }
}
