import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { QuandlStockData } from '../../models/market-data.model';

@Component({
  selector: 'app-quandl-stock',
  templateUrl: './quandl-stock.component.html',
  styleUrls: ['./quandl-stock.component.css']
})
export class QuandlStockComponent implements OnInit {
  ticker = 'IBM';
  startDate: string = '2000-01-01';
  endDate: string = '2000-03-01';
  key: string;
  displayedCols = ['date', 'open', 'high', 'low', 'close', 'volume', 'openAdj', 'highAdj', 'lowAdj', 'closeAdj', 'volumeAdj'];

  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.key = localStorage.getItem('qdKey');
    if (this.key) {
      if (this.key.length > 6) this.getStock(this.ticker, this.startDate, this.endDate);
    } else this.key = 'qdkey';
  }

  get stock(): QuandlStockData[] {
    return this.repository.quandlStock;
  }

  getStock(ticker: string, start: string, end: string) {
    this.repository.getQuandlStock(ticker, start, end, this.key);
  }
}
