import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-api-key-quandl',
  templateUrl: './get-api-key-quandl.component.html',
  styleUrls: ['./get-api-key-quandl.component.css']
})
export class GetApiKeyQuandlComponent implements OnInit {
  key: string;
  key1: string = 'qdkey';
  key2: string = 'qdkey';

  constructor() {
    //localStorage.setItem('qdKey', 'qdkey');
  }

  ngOnInit() {
    this.key1 = localStorage.getItem('qdKey');
    if (this.key1 == null) this.key1 = 'qdkey';
    this.key = this.key1;
  }

  setKey() {
    if (this.key.length > 6) {
      localStorage.setItem('qdKey', this.key);
      this.key2 = localStorage.getItem('qdKey');
    }
  }
}
