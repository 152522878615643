import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parametric3d',
  templateUrl: './parametric3d.component.html',
  styleUrls: ['./parametric3d.component.css']
})
export class Parametric3dComponent implements OnInit {
  options: any;

  constructor() { }

  ngOnInit() {
    this.options = this.getOptions();
  }

  getOptions() {
    return {
      tooltip: {},
      backgroundColor: '#fff',
      visualMap: {
        show: false,
        dimension: 2,
        top: '5%',
        min: -0.25,
        max: 0.25,
        inRange: {
          color: ["#00008F", "#0000FF", "#006FFF", "#00DFFF", "#4FFFAF", "#BFFF3F", "#FFCF00", "#FF5F00", "#EF0000", "#7F0000"]
        }
      },
      xAxis3D: {
        type: 'value'
      },
      yAxis3D: {
        type: 'value'
      },
      zAxis3D: {
        type: 'value'
      },
      grid3D: {
        viewControl: {
          projection: 'perspective',
        },
        boxHeight: 25,
        top: '-10%'
      },
      series: [{
        type: 'surface',
        parametric: true,

        parametricEquation: {
          u: {
            min: 0,
            max: 2.01 * Math.PI,
            step: Math.PI / 50
          },
          v: {
            min: 0,
            max: 2.01 * Math.PI,
            step: Math.PI / 20
          },
          x: function (u, v) {
            return Math.cos(u) * (1 + 0.25 * Math.cos(v));
          },
          y: function (u, v) {
            return Math.sin(u) * (1 + 0.25 * Math.cos(v));
          },
          z: function (u, v) {
            return 0.25 * Math.sin(v);
          }
        }
      }]
    }
  }
}
