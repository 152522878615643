import { Component, OnInit } from '@angular/core';
import { Ch10Service } from '../../models/ch10.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch10-simple-bond',
  templateUrl: './ch10-simple-bond.component.html',
  styleUrls: ['./ch10-simple-bond.component.css']
})
export class Ch10SimpleBondComponent implements OnInit {
  evalDate: string = '2015-12-16';
  issueDate: string = '2015-12-16';
  timeToMaturity: number = 10;
  settlementDays: number = 1;
  faceValue: number = 1000;
  rate: number = 0.06;
  coupon: string = '0.05';
  frequency: string = 'annual'
  bondResult: any;
  curveRateResult: any;
  bondCols: any;
  curveRateCols: any;
  private helper: ModelHelper;

  constructor(private service: Ch10Service) { }

  ngOnInit() {
    this.getBondPrice(this.evalDate, this.issueDate, this.timeToMaturity, this.settlementDays, this.faceValue, this.rate, this.coupon, this.frequency);
    this.getBondPriceCurveRate();
  }

  getBondPrice(evalDate: string, issueDate: string, timeToMaturity: number, settlementDays: number, faceValue: number, rate: number,
    coupon: string, frequency: string) {
    this.bondResult = null;
    this.service.getBondPrice(evalDate, issueDate, timeToMaturity, settlementDays, faceValue, rate, coupon, frequency)
      .subscribe(result => {
        this.bondResult = [result.result];
        this.helper = new ModelHelper();
        this.bondCols = this.helper.setColDefs([result.result], 120);
      });
  }

  getBondPriceCurveRate() {
    this.curveRateResult = null;
    this.service.getBondPriceCurveRate().subscribe(result => {
      this.curveRateResult = result.result;
      this.helper = new ModelHelper();
      this.curveRateCols = this.helper.setColDefs(result.result, 250);
    });
  }
}
