import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.css']
})
export class AreaChartComponent implements OnInit {
  simple: any;
  multiple: any;
  stack: any;

  private xdata = [];
  private data1 = [];
  private data2 = [];
  private data3 = [];

  constructor() { }

  ngOnInit() {
    this.getData();
    this.simple = this.getSimple();
    this.multiple = this.getMultiple();
    this.stack = this.getStack();
  }

  getData() {
    for (let i = 0; i <= 7; i++) {
      this.xdata.push(i);
      this.data1.push(1.2 + Math.sin(i));
      this.data2.push(1.2 + Math.cos(i));
      this.data3.push(1.2 + Math.sin(i) * Math.cos(i));
    }
  }

  private getSimple() {
    return {
      title: {
        text: 'Simple Area',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      xAxis: {
        data: this.xdata,
        type: 'category',
        boundaryGap: false
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: this.data1,
        type: 'line',
        areaStyle: {},
        symbol: 'none'
      }]
    };
  }

  private getMultiple() {
    return {
      title: {
        text: 'Multiple Area',
      },
      legend: {
        top: '8%',
        left: '15%'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      xAxis: {
        data: this.xdata,
        type: 'category',
        boundaryGap: false
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: this.data1,
        name: 'data1',
        type: 'line',
        areaStyle: {},
        symbol: 'none'
      }, {
        data: this.data2,
        name: 'data2',
        type: 'line',
        areaStyle: {},
        symbol: 'none'
      }, {
        data: this.data3,
        name: 'data3',
        type: 'line',
        areaStyle: {},
        symbol: 'none'
      }]
    };
  }

  private getStack() {
    return {
      title: {
        text: 'Stack Area',
      },
      legend: {
        top: '8%',
        left: '15%'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      xAxis: {
        data: this.xdata,
        type: 'category',
        boundaryGap: false
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        data: this.data1,
        name: 'data1',
        type: 'line',
        areaStyle: {},
        stack: 'total',
        symbol: 'none'
      }, {
        data: this.data2,
        name: 'data2',
        type: 'line',
        areaStyle: {},
        stack: 'total',
        symbol: 'none'
      }, {
        data: this.data3,
        name: 'data3',
        type: 'line',
        areaStyle: {},
        stack: 'total',
        symbol: 'none'
      }]
    };
  }
}
