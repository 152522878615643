import { Component, OnInit } from '@angular/core';
import { Ch10Service } from '../../models/ch10.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch10-conv-bond',
  templateUrl: './ch10-conv-bond.component.html',
  styleUrls: ['./ch10-conv-bond.component.css']
})
export class Ch10ConvBondComponent implements OnInit {
  bondCols: any;
  bondResult: any;
  engineType: string = 'binomialJarrowRudd';
  private helper: ModelHelper;

  constructor(private service: Ch10Service) { }

  ngOnInit() {
    this.getBondPrice(this.engineType);
  }

  getBondPrice(engineType: string) {
    this.bondResult = null;
    this.service.getConvertibleBondPrice(engineType).subscribe(result => {
      this.bondResult = result.result;
      this.helper = new ModelHelper();
      this.bondCols = this.helper.setColDefs(result.result, 150);
    });
  }
}
