import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-api-key',
  templateUrl: './get-api-key.component.html',
  styleUrls: ['./get-api-key.component.css']
})
export class GetApiKeyComponent implements OnInit {
  key: string;
  key1: string = 'avkey';
  key2: string = 'avkey';

  constructor() {
    //localStorage.setItem('avKey', 'avkey');
  }

  ngOnInit() {
    this.key1 = localStorage.getItem('avKey');
    if (this.key1 == null) this.key1 = 'avkey';
    this.key = this.key1;
  }

  setKey() {
    if (this.key.length > 6) {
      localStorage.setItem('avKey', this.key);
      this.key2 = localStorage.getItem('avKey');
    }
  }
}
