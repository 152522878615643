import { Component, OnInit } from '@angular/core';
import { Ch8Service } from '../../models/ch8.service';

@Component({
  selector: 'app-ch8-svm-regr',
  templateUrl: './ch8-svm-regr.component.html',
  styleUrls: ['./ch8-svm-regr.component.css']
})
export class Ch8SvmRegrComponent implements OnInit {
  ticker = 'IBM';
  start: string = '2016-01-01';
  end: string = '2018-12-31';
  trainDate: string = '2017-12-31';
  period: number = 14;
  isNormalized: boolean = false;
  windowSize: number = 2;

  titleTrain: string;
  titleTest: string;
  mlResult: any;

  colDefs = [
    { headerName: "Date", field: "date", width: 100 },
    { headerName: "Expect", field: "expected", width: 100 },
    { headerName: "Predict", field: "predicted", width: 100 },
  ];

  constructor(private repository: Ch8Service) { }

  ngOnInit() {
    this.getMlResult(this.ticker, this.start, this.end, this.period, this.trainDate, this.isNormalized, this.windowSize);
  }  

  getMlResult(ticker: string, start: string, end: string, period: number, trainDate: string,
    isNormalized: boolean, windowSize: number) {
    this.mlResult = null;
    this.titleTrain = ticker + ": Regression for Training Data";
    this.titleTest = ticker + ":  Regression for Test Data";
    this.repository.getMlSvmRegr(ticker, start, end, period, trainDate, isNormalized, windowSize).subscribe(result => {
      this.mlResult = result;
    });    
  }
}
