import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { MarketData } from '../../models/market-data.model';

@Component({
  selector: 'app-av-stock',
  templateUrl: './av-stock.component.html',
  styleUrls: ['./av-stock.component.css']
})

export class AvStockComponent implements OnInit {
  ticker: string = 'MSFT';
  startDate: string = '2018-08-01';
  endDate: string = '2018-11-01';
  period: string = 'daily';
  displayedCols = ['date', 'open', 'high', 'low', 'close', 'closeAdj', 'volume'];
  key: string;

  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.key = localStorage.getItem('avKey');
    if (this.key) {
      if (this.key.length > 6) this.getStock(this.ticker, this.startDate, this.endDate, this.period);
    } else this.key = 'avkey';
  }

  get stock(): MarketData[] {
    return this.repository.avStock;
  }

  getStock(ticker: string, start: string, end: string, period: string) {
   this.repository.getAvStock(ticker, start, end, period, this.key);
  }
}
