import { Component, OnInit } from '@angular/core';
import { MlrOutput } from '../../models/ch6.model';
import { Ch6Service } from '../../models/ch6.service'

@Component({
  selector: 'app-ch6-mlr-index',
  templateUrl: './ch6-mlr-index.component.html',
  styleUrls: ['./ch6-mlr-index.component.css']
})
export class Ch6MlrIndexComponent implements OnInit {
  title: string = 'MLR';

  constructor(private repository: Ch6Service) { }

  ngOnInit() {
    this.getMlrOutput();
  }  

  get mlrOutput(): MlrOutput {
    return this.repository.mlrIndex;
  }

  getMlrOutput() {
    this.repository.getMlrIndex();
  }
}
