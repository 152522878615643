import { Component, OnInit } from '@angular/core';
import { Ch11Service } from '../../models/ch11.service';
import { StrategyHelper } from '../../models/strategy.helper';

@Component({
  selector: 'app-ch11-pnl-zscore',
  templateUrl: './ch11-pnl-zscore.component.html',
  styleUrls: ['./ch11-pnl-zscore.component.css']
})
export class Ch11PnlZscoreComponent implements OnInit {
  ticker: string = 'IBM';
  start: string = '2010-01-01';
  end: string = '2015-12-31';
  period: number = 40;
  fastPeriod: number = 7;
  slowPeriod: number = 25;
  maType: string = 'sma';
  zEntry: number = 1.0;
  zExit: number = 0.0;
  notional: number = 10000;
  stopLoss: number = 0.1;
  protectProfit: number = 0.3;
  signalType: string = 'ma';
  data: any;
  options: any;
  private helper: StrategyHelper;

  colDefs: any = [
    { headerName: "Strategy", field: "strategy", width: 150 },
    { headerName: "P&L", field: "pnl", width: 100 },
    { headerName: "# of Trades", field: "numTrades", width: 100 },
    { headerName: "Sharpe Ratio", field: "sharpe", width: 110 },
    { headerName: "Max Drawdown", field: "drawdown", width: 130 },
  ];
  rowDefs: any;

  constructor(private service: Ch11Service) { }

  ngOnInit() {
    this.getData(this.ticker, this.start, this.end, this.period, this.fastPeriod, this.slowPeriod, this.maType,
      this.zEntry, this.zExit, this.notional, this.stopLoss, this.protectProfit, this.signalType);
  }

  getData(ticker: string, start: string, end: string, period: number, fastPeriod: number, slowPeriod: number, maType: string,
    zEntry: number, zExit: number, notional: number, stopLoss: number, protectProfit: number, signalType: string) {
    this.data = null;
    this.helper = new StrategyHelper();
    this.rowDefs = [];
    let strategy = "MA Zscore";
    if (signalType == 'rsiZscore') strategy = 'RSI Z-Score';
    else if (signalType == 'ppoZscore') strategy = 'PPO Z-Score';
    this.service.getPnlZscore(ticker, start, end, period, fastPeriod, slowPeriod, maType, zEntry, zExit,
      notional, stopLoss, protectProfit, signalType).subscribe(result => {
        this.data = result;
        let risk = result.risk;
        this.options = this.helper.chartPnl(this.data);
        this.rowDefs.push({
          strategy: strategy, pnl: risk.pnL.toFixed(0), numTrades: risk.numberTrades,
          sharpe: risk.sharpe, drawdown: risk.maxDrawdown.toFixed(2)
        });
        this.rowDefs.push({
          strategy: 'Buy-Hold', pnl: risk.pnL1.toFixed(0), numTrades: 1,
          sharpe: risk.sharpe1, drawdown: risk.maxDrawdown1.toFixed(2)
        });
      });
  }
}
