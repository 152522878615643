import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-av-key',
  templateUrl: './av-key.component.html',
  styleUrls: ['./av-key.component.css']
})
export class AvKeyComponent implements OnInit {
  key: string;
  constructor() {
  }

  ngOnInit() {    
    this.key = localStorage.getItem('avKey');
  }
}
