import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class Ch10Service {
  private url;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
  }

  getBondPrice(evalDate: string, issueDate: string, timeToMaturity: number, settlementDays: number, faceValue: number,
    rate: number, coupon: string, frequency: string) {
    let url1 = this.url + 'api/bondprice/' + evalDate + '/' + issueDate + '/' + timeToMaturity + '/' + settlementDays + '/' + faceValue +
      '/' + rate + '/' + coupon + '/' + frequency;
    return this.http.get<any>(url1);
  }

  getBondPriceCurveRate() {
    return this.http.get<any>(this.url + 'api/bondpricecurverate');
  }

  getZeroCouponDirect() {
    return this.http.get<any>(this.url + 'api/zerocoupondirect');
  }

  getZeroCouponBootstrap(curveType: string) {
    return this.http.get<any>(this.url + 'api/zerocouponbootstrap/' + curveType);
  }

  getZeroCouponBootstrapZspread(zSpread: number, curveType: string) {
    return this.http.get<any>(this.url + 'api/zerocouponbootstrapzspread/' + zSpread + '/' + curveType);
  }

  getZeroCouponInterbank() {
    return this.http.get<any>(this.url + 'api/zerocouponinterbank');
  }

  getCallableBondPrice(evalDate: string, issueDate: string, callDate: string, maturity: string, coupon: number,
    sigma: number, a: number, rate: number) {    
      let url1 = this.url + 'api/callableBondprice/' + evalDate + '/' + issueDate + '/' + callDate + '/' + maturity + '/' + coupon +
        '/' + sigma + '/' + a + '/' + rate;
      return this.http.get<any>(url1);
  }

  getConvertibleBondPrice(engineType: string) {
    return this.http.get<any>(this.url + 'api/convertiblebondprice/' + engineType);
  }

  getCdsHazardRate() {
    return this.http.get<any>(this.url + 'api/cdshazardrate');
  }

  getCdsPv(protectionSide: string, ccy: string, evalDate: string, effectiveDate: string, maturity: string, recoveryRate: number,
    spreads: string, tenors: string, notional: number, couponFrequency: string, coupon: number, flatRate: number) {
    let url1 = this.url + 'api/cdspv/' + protectionSide + '/' + ccy + '/' + evalDate + '/' + effectiveDate + '/' + maturity + '/' +
      recoveryRate + '/' + spreads + '/' + tenors + '/' + notional + '/' + couponFrequency + '/' + coupon + '/' + flatRate;
    return this.http.get<any>(url1);
  }

  getCdsPrice(protectionSide: string, ccy: string, evalDate: string, effectiveDate: string, maturity: string, recoveryRate: number,
    spreads: string, tenors: string, couponFrequency: string, coupon: number, flatRate: number) {
    let url1 = this.url + 'api/cdsprice/' + protectionSide + '/' + ccy + '/' + evalDate + '/' + effectiveDate + '/' + maturity + '/' +
      recoveryRate + '/' + spreads + '/' + tenors + '/' + couponFrequency + '/' + coupon + '/' + flatRate;
    return this.http.get<any>(url1);
  }
}
