import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../models/repository.service';
import { Symbol } from '../../models/symbol.model';

@Component({
  selector: 'app-stock-price',
  templateUrl: './stock-price.component.html',
  styleUrls: ['./stock-price.component.css']
})
export class StockPriceComponent implements OnInit {
  public stockId: number = 1;
  public ticker = 'A';
  public startDate: string = '2018-07-01';
  public endDate: string = '2018-08-01';
  public startDate1: string = '2018-07-01';
  public endDate1: string = '2018-08-01';
  
  constructor(private repository: RepositoryService) { }

  ngOnInit() {
    this.getStockAndPrice(this.stockId, this.startDate, this.endDate);
    this.getStockAndPriceWithTicker(this.ticker, this.startDate1, this.endDate1);
  }

  get stock(): Symbol {
    return this.repository.stock;
  }

  get stock1(): Symbol {
    return this.repository.stock1;
  }

  getStockAndPrice(id: number, start: string, end: string) {
    this.repository.getStockAndPrice(id, start, end);
  }

  getStockAndPriceWithTicker(ticker: string, start: string, end: string) {
    this.repository.getStockAndPriceWithTicker(ticker, start, end);
  }
}
