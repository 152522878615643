import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-ch10-line-chart2',
  templateUrl: './ch10-line-chart2.component.html',
  styleUrls: ['./ch10-line-chart2.component.css']
})
export class Ch10LineChart2Component implements OnInit, OnChanges {
  @Input() data: any;
  zcOptions: any;
  discOptions: any;
  
  constructor() { }

  ngOnInit() {
    this.zcOptions = this.getOptions(this.data, 'zc');
    this.discOptions = this.getOptions(this.data, 'disc');
  }

  ngOnChanges() {
    this.zcOptions = this.getOptions(this.data, 'zc');
    this.discOptions = this.getOptions(this.data, 'disc');
  }

  getOptions(data: any, chartType: string) {   
    let title = 'Zero-Coupon Yield';
    let ylabel = 'Zero-Coupon Rate';
    let seriesNames = ['R', 'R with ZSpread'];
    let top = '11%';
    let data1 = [];
    let data2 = [];

    if (chartType == 'zc') {
      data.forEach(function (d) {
        data1.push([d['timesToMaturity'], d["zero Coupon Rate: R"]]);
        data2.push([d['timesToMaturity'], d["zero Coupon Rate: R with ZSpread"]]);
      })
    } else if (chartType == 'disc') {
      title = 'Discount Yield';
      ylabel = 'Disount Rate';
      seriesNames = ['B', 'B with ZSpread'];
      top = "70%";
      data.forEach(function (d) {
        data1.push([d['timesToMaturity'], d["discount Rate: B"]]);
        data2.push([d['timesToMaturity'], d["discount Rate: B with ZSpread"]]);
      })
    }


    return {
      legend: {
        top: top,
        left: '17%',
        orient: 'vertical',
        data: [{ name: seriesNames[0], icon: 'line' }, { name: seriesNames[1], icon: 'line' }]
      },
      grid: {
        top: 35,
        left: 70,
        right: 10
      },
      title: {
        text: title,
        left: 'center'
      },
      xAxis: {
        type: 'value',
        name: 'Times to Maturity',
        nameLocation: 'center',
        nameGap: '30',
        axisLine: { onZero: false }
      },
      yAxis: {
        type: 'value',
        scale: true,
        name: ylabel,
        nameLocation: 'center',
        nameGap: '50'
      },
      series: [{
        name: seriesNames[0],
        type: 'line',
        data: data1,
        showSymbol: false
      },
      {
        name: seriesNames[1],
        type: 'line',
        data: data2,
        showSymbol: false
      }]
    };
  }
}
