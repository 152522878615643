import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { RepositoryService } from '../../../models/repository.service';
//import { Symbol } from '../../../models/symbol.model';
import { StockDeleteDialogComponent } from './stock-delete-dialog/stock-delete-dialog.component';

@Component({
  selector: 'app-stock-delete',
  templateUrl: './stock-delete.component.html',
  styleUrls: ['./stock-delete.component.css']
})
export class StockDeleteComponent implements OnInit {
  public stockId: number;
  public isDeleted: boolean;
  constructor(private repository: RepositoryService, private dialog: MatDialog) {
    this.stockId = 1;
    this.isDeleted = false;
  }

  ngOnInit() {
  }

  get stock() {
    return this.repository.stock;
  }

  getStock() {
    this.isDeleted = false;
    this.repository.getStock(this.stockId);
  }

  deleteStock() {
    this.repository.deleteStock(this.stockId);
    this.isDeleted = true;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      stock: this.stock
    }

    const dialogRef = this.dialog.open(StockDeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteStock();
      }
    });
  }
}
