import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.css']
})
export class StockListComponent implements OnInit {
  public stocks: Stock[];
  @Output() selected = new EventEmitter<Stock>();

  constructor() { }

  ngOnInit() {
    this.stocks = [
      new Stock(1, "AAPL", 207.48, true),
      new Stock(2, "AMZN", 1665.53, false),
      new Stock(3, "GOOG", 1057.79, true),
      new Stock(4, "IBM", 115.67, false),
      new Stock(5, "MSFT", 106.16, true),
    ];
  }

  select(selectedStock: Stock) {
    this.selected.emit(selectedStock);
  }
}

export class Stock {
  constructor(public id: number, public ticker: string, public price: number, public holdStock: boolean) {
  }
}
