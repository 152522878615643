import { Component, OnInit } from '@angular/core';
import { Ch9Service } from '../../models/ch9.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch9-option-barrier',
  templateUrl: './ch9-option-barrier.component.html',
  styleUrls: ['./ch9-option-barrier.component.css']
})
export class Ch9OptionBarrierComponent implements OnInit {
  optionResult: any;
  colDefs: any;

  inputColDefs: any = [
    { headerName: "Parameter", field: "param", width: 100 },
    { headerName: "Value", field: "value", width: 100, editable: true },
    { headerName: "Description", field: "desc", width: 300 },
  ];

  inputRows: any = [
    { param: 'Option Type', value: 'C', desc: 'P for a put option, otherwise a call option' },
    { param: 'Spot', value: 100, desc: 'Current price of the underlying asset' },
    { param: 'Strike', value: 100, desc: 'Strike price' },
    { param: 'Rate', value: 0.1, desc: 'Interest rate' },
    { param: 'Div Yield', value: 0.06, desc: 'Continuous dividend yield' },
    { param: 'Vol', value: 0.3, desc: 'Volatility' },
    { param: 'Barrier', value: 90, desc: 'Barrier level' },
    { param: 'Rebate', value: 0, desc: 'Paid off if barrier is not knocked in during if life' },
    { param: 'Engine Type', value: 'analytic', desc: 'Pricing engine type' },
  ];

  private helper: ModelHelper;  

  constructor(private repository: Ch9Service) { }

  ngOnInit() {
    this.getOptionResult(this.inputRows);
  }

  getOptionResult(rows: any) {
    this.optionResult = null;

    this.repository.getBarrierOptions(rows[0].value, rows[2].value, rows[1].value, rows[6].value, rows[7].value, rows[4].value,
      rows[3].value, rows[5].value, rows[8].value).subscribe(result => {
        this.optionResult = result.result;
        this.helper = new ModelHelper();
        this.colDefs = this.helper.setColDefs(result.result, 100);
      });
  }
}
