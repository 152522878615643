import { Component, OnInit } from '@angular/core';
import { Ch8Service } from '../../models/ch8.service';

@Component({
  selector: 'app-ch8-ml-data',
  templateUrl: './ch8-ml-data.component.html',
  styleUrls: ['./ch8-ml-data.component.css']
})
export class Ch8MlDataComponent implements OnInit {
  ticker = 'FB';
  start: string = '2018-01-01';
  end: string = '2018-12-31';
  trainDate: string = '2018-07-31';
  period: number = 14;
  mlType: string = 'classification';
  isNormalized: boolean = false;
  windowSize: number = 3;

  columnDefs = [
    { headerName: "Date", field: "date", width: 100 },    
    { headerName: "Close", field: "close", width: 80 },
    { headerName: "Price", field: "price", width: 80 },
    { headerName: "MA", field: "ma", width: 80 },
    { headerName: "Expect", field: "expected", width: 80 },
  ]

  columnDefs2: any;

  constructor(private repository: Ch8Service) { }

  ngOnInit() {
    this.columnDefs2 = this.getColDef2();
    this.getMlData(this.ticker, this.start, this.end, this.period, this.mlType, this.trainDate, this.isNormalized, this.windowSize);
  }

  get mlStockData(): any {
    return this.repository.mlStockData;
  }

  get mlProcessedData(): any {
    return this.repository.mlProcessedData;
  }

  getMlData(ticker: string, start: string, end: string, period: number, mlType: string, trainDate: string,
    isNormalized: boolean, windowSize: number) {
    this.repository.getMlStockData(ticker, start, end, period, mlType);
    this.repository.getMlProcessedData(ticker, start, end, period, mlType, trainDate, isNormalized, windowSize);    
  }

  getColDef2() {
    let col2 = [
      { headerName: "Date", field: "date", width: 100, pinned: true  },
      { headerName: "Close", field: "close", width: 80 },
      { headerName: "Price", field: "price", width: 80 },
      { headerName: "MA", field: "ma", width: 80 },
      { headerName: "Expect", field: "expected", width: 80 }
    ];

    for (let i = 0; i < this.windowSize; i++) {
      col2.push({ headerName: "Close" + (i + 1).toString(), field: "close" + (i + 1).toString(), width: 80 });
      col2.push({ headerName: "Price" + (i + 1).toString(), field: "price" + (i + 1).toString(), width: 80 });
      col2.push({ headerName: "MA" + (i + 1).toString(), field: "ma" + (i + 1).toString(), width: 80 });
    }
    return col2;
  }
}
