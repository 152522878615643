import { Component, OnInit } from '@angular/core';
import { Ch11Service } from '../../models/ch11.service';
import { StrategyHelper } from '../../models/strategy.helper';

@Component({
  selector: 'app-ch11-zscore-macd',
  templateUrl: './ch11-zscore-macd.component.html',
  styleUrls: ['./ch11-zscore-macd.component.css']
})
export class Ch11ZscoreMacdComponent implements OnInit {
  ticker: string = 'IBM';
  start: string = '2011-06-01';
  end: string = '2013-08-01';
  fastPeriod: number = 12;
  slowPeriod: number = 26;
  signalPeriod: number = 9;
  zEntry: number = 1.0;
  zExit: number = 0;
  data: any;
  options: any;
  private helper: StrategyHelper;

  constructor(private service: Ch11Service) { }

  ngOnInit() {
    this.getData(this.ticker, this.start, this.end, this.fastPeriod, this.slowPeriod, this.signalPeriod, this.zEntry, this.zExit);
  }

  getData(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod: number, signalPeriod: number, zEntry: number, zExit: number) {
    this.data = null;
    this.helper = new StrategyHelper();
    this.service.getStrategyMacdZscore(ticker, start, end, fastPeriod, slowPeriod, signalPeriod, zEntry, zExit)
      .subscribe(result => {
        this.data = result;
        this.options = this.helper.chartPrice2(this.data, false, 0, [zEntry, zExit]);
      });
  }
}
