import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-polar-chart',
  templateUrl: './polar-chart.component.html',
  styleUrls: ['./polar-chart.component.css']
})
export class PolarChartComponent implements OnInit {
  polar1: any;
  polar2: any;

  private data1 = [];
  private data2 = [];
  private data3 = [];
  
  constructor() { }

  ngOnInit() {
    this.getData();
    this.polar1 = this.getPolar1();
    this.polar2 = this.getPolar2();
  }

  getData() {
    for (let i = 0; i <= 360; i++) {
      let theta = i / 180 * Math.PI;
      let r1 = Math.sin(2 * theta) * Math.cos(2 * theta);
      let r2 = Math.log(1 + Math.cos(2 * theta));
      let r3 = Math.log(1 + Math.sin(2 * theta));
      this.data1.push([r1, i]);
      this.data2.push([r2, i]);
      this.data3.push([r3, i]);
    }    
  }

  getPolar1() {
    return {
      title: {
        text: 'Single Line'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      polar: {
        center: ['50%', '50%']
      },
      angleAxis: {
        type: 'value',
        startAngle: 0
      },
      radiusAxis: {
        min: 0
      },
      series: [{
        data: this.data1,
        type: 'line',
        coordinateSystem: 'polar',
        showSymbol: false
      }]
    };
  }

  getPolar2() {
    return {
      title: {
        text: 'Multiple Lines'
      },
      legend: {
        x: 'center',
        y: 'bottom'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      polar: {
        center: ['50%', '50%']
      },
      angleAxis: {
        type: 'value',
        startAngle: 0
      },
      radiusAxis: {
        min: -5,
        max: 1
      },
      series: [{
        data: this.data2,
        name: 'cosine',
        type: 'line',
        coordinateSystem: 'polar',
        showSymbol: false
      }, {
          data: this.data3,
          name: 'sine',
        type: 'line',
        coordinateSystem: 'polar',
        showSymbol: false
      }]
    };
  }
}
