import { Component, OnInit } from '@angular/core';
import { Stock } from './stock-list/stock-list.component';

@Component({
  selector: 'app-input-output',
  templateUrl: './input-output.component.html',
  styleUrls: ['./input-output.component.css']
})
export class InputOutputComponent implements OnInit {
  selectedStock: Stock;

  constructor() {
    this.selectedStock = null;
  }

  ngOnInit() {
  }

  showStockDetails(stck: Stock) {
    this.selectedStock = stck;
  }
}
