import { Component, OnInit } from '@angular/core';
import { Ch10Service } from '../../models/ch10.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch10-zc-bootstrap',
  templateUrl: './ch10-zc-bootstrap.component.html',
  styleUrls: ['./ch10-zc-bootstrap.component.css']
})
export class Ch10ZcBootstrapComponent implements OnInit {
  zcResult: any;
  zcCols: any;
  curveType: string = 'dataPoints'
  private helper: ModelHelper;

  constructor(private service: Ch10Service) { }

  ngOnInit() {
    this.getZcResult(this.curveType);
  }

  getZcResult(curveType: string) {
    this.zcResult = null;
    this.service.getZeroCouponBootstrap(curveType).subscribe(result => {
      this.zcResult = result.result;
      this.helper = new ModelHelper();
      this.zcCols = this.helper.setColDefs(result.result, 170);
    });
  }
}
