import { Component, Injectable } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';

@Injectable()
export class FileData {
  dataChange = new BehaviorSubject<FileNode[]>([]);

  get data(): FileNode[] { return this.dataChange.value; }

  constructor() {
    this.initialize();
  }

  initialize() {
    const dataObject = JSON.parse(TREE_DATA);
    const data = this.buildFileTree(dataObject, 0);
    this.dataChange.next(data);
  }
  
  buildFileTree(obj: { [key: string]: any }, level: number): FileNode[] {
    return Object.keys(obj).reduce<FileNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new FileNode();
      node.filename = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.type = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }
}


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
  providers: [FileData]
})
export class NavMenuComponent {
  treeControl: FlatTreeControl<FileFlatNode>;
  treeFlattener: MatTreeFlattener<FileNode, FileFlatNode>;
  dataSource: MatTreeFlatDataSource<FileNode, FileFlatNode>;

  constructor(database: FileData) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
      this._isExpandable, this._getChildren);
    this.treeControl = new FlatTreeControl<FileFlatNode>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe(data => this.dataSource.data = data);
  }

  transformer = (node: FileNode, level: number) => {
    return new FileFlatNode(!!node.children, node.filename, level, node.type);
  }

  private _getLevel = (node: FileFlatNode) => node.level;

  private _isExpandable = (node: FileFlatNode) => node.expandable;

  private _getChildren = (node: FileNode): Observable<FileNode[]> => observableOf(node.children);

  hasChild = (_: number, _nodeData: FileFlatNode) => _nodeData.expandable;
}


export class FileNode {
  children: FileNode[];
  filename: string;
  type: any;
}

export class FileFlatNode {
  constructor(
    public expandable: boolean,
    public filename: string,
    public level: number,
    public type: any) { }
}

const TREE_DATA = JSON.stringify({
  'Chapter 1': {
    'Counter': '/chapter01/counter',
    'Fetch Data': '/chapter01/fetch-data'
  },
  'Chapter 2': {
    'Data Binding: Interpolation': '/chapter02/interpolation',
    'Data Binding: One-Way': '/chapter02/one-way-binding',
    'Data Binding: Event': '/chapter02/event-binding',
    'Data Binding: Two-Way': '/chapter02/two-way-binding',
    'Directive Test': '/chapter02/directive-test',
    'Input-Output Test': '/chapter02/input-output'
  },
  'Chapter 3': {
    'All Stocks': '/chapter03/all-stocks',
    'Stock Prices': '/chapter03/stock-price',
    'Index Data': '/chapter03/index-data',
    'Stock CRUD': '/chapter03/stock-crud'
  },
  'Chapter 4': {
    'Yahoo Stock Data': '/chapter04/yahoo-stock',
    'Save Yahoo Stock Data': '/chapter04/yahoo-stock-save',
    'IEX Stock Data': '/chapter04/iex-stock',
    'IEX Real-Time Stock Quote': '/chapter04/iex-quote',
    'Set Alpha-Vantage API Key': '/chapter04/get-api-key',
    'Alpha Vantage EOD Stock Data': '/chapter04/av-stock',
    'Alpha Vantage Intraday Stock Data': '/chapter04/av-bar',
    'Alpha Vantage Real-Time Quotes': '/chapter04/av-quote',
    'Alpha Vantage EOD FX Data': '/chapter04/av-fx',
    'Alpha Vantage Intraday FX Data': '/chapter04/av-fx-bar',
    'Alpha Vantage Sector Performance': '/chapter04/av-sector',
    'Set Quandl API Key': '/chapter04/get-api-key-quandl',
    'Quandl EOD Stock Data': '/chapter04/quandl-stock',
    'ISDA Interest Rates': '/chapter04/isda'
  },
  'Chapter 5': {
    'Line Charts': '/chapter05/chart-line',
    'Specialized Charts': '/chapter05/chart-specialized',
    '3D Charts': '/chapter05/chart3d',
    'Stock Charts': '/chapter05/chart-stock',
    'Real-Time Stock Charts': '/chapter05/realtime-stock',
    'Real-Time Stock Charts with SignalR': '/chapter05/realtime-server'
  },
  'Chapter 6': {
    'SRL Index Charts': '/chapter06/ch6-slr-index',
    'SRL Stock Charts': '/chapter06/ch6-slr-stock',
    '2D PCA Index Charts': '/chapter06/ch6-pca2d-index',
    '2D PCA Stock Charts': '/chapter06/ch6-pca2d-stock',
    'MRL Index Charts': '/chapter06/ch6-mlr-index',
    'MRL Stock Charts': '/chapter06/ch6-mlr-stock',
    'PCA Index Charts': '/chapter06/ch6-pca-index',
    'PCA Stock Charts': '/chapter06/ch6-pca-stock',
  },
  'Chapter 7': {
    'Moving Averages': '/chapter07/ch7-ind-ma',
    'Bollinger Bands': '/chapter07/ch7-ind-bbands',
    'Parabolic SAR': '/chapter07/ch7-ind-sar',
    'Average Directional Index (ADX)': '/chapter07/ch7-ind-adx',
    'Absolute Price Oscillator (APO)': '/chapter07/ch7-ind-apo',
    'Aroon Indicator': '/chapter07/ch7-ind-aroon',
    'Balance of Power (BOP)': '/chapter07/ch7-ind-bop',
    'Commodity Channel Index (CCI)': '/chapter07/ch7-ind-cci',
    'MACD': '/chapter07/ch7-ind-macd',
    'Relative Strength Index (RSI)': '/chapter07/ch7-ind-rsi',
    'Stochastic Oscillator': '/chapter07/ch7-ind-stoch',
    "Williams' %R": '/chapter07/ch7-ind-willr',
    'Accumulation/Distribution (AD)': '/chapter07/ch7-ind-ad',
    'On-Balance Volume (OBV)': '/chapter07/ch7-ind-obv',
    'Average True Range (ATR)': '/chapter07/ch7-ind-atr',
    'Normalized ATR': '/chapter07/ch7-ind-natr',
    'True Range': '/chapter07/ch7-ind-tr'
  },
  'Chapter 8': {
    'Prepare ML Data': '/chapter08/ch8-ml-data',
    'KNN Classification': '/chapter08/ch8-knn-class',
    'SVM Classification': '/chapter08/ch8-svm-class',
    'SVM Regression': '/chapter08/ch8-svm-regr',
    'Neural Network Classification': '/chapter08/ch8-ann-class',
    'Neural Network Regression': '/chapter08/ch8-ann-regr'
  },
  'Chapter 9': {
    'European Options': '/chapter09/ch9-option-eu',
    'European Options: Implied Volatility': '/chapter09/ch9-vol-eu',
    'American Options': '/chapter09/ch9-option-am',
    'Barrier Options': '/chapter09/ch9-option-barrier',
    'Bermudan Options': '/chapter09/ch9-option-bermudan',
    'Real-World American Options': '/chapter09/ch9-option-realworld'
  },
  'Chapter 10': {
    'Simple Bonds': '/chapter10/ch10-simple-bond',
    'Zero-Coupon Yield: Treasury Bonds: Implied Volatility': '/chapter10/ch10-zc-direct',
    'Zero-Coupon Yield: Bootstrap': '/chapter10/ch10-zc-bootstrap',
    'Zero-Coupon Yield: Interbank Rates': '/chapter10/ch10-zc-interbank',
    'Zero-Coupon Yield: Z-Spread': '/chapter10/ch10-zc-zspread',
    'Callable Bonds': '/chapter10/ch10-callable-bond',
    'Convertible Bonds': '/chapter10/ch10-conv-bond',
    'CDS Hazard Rates': '/chapter10/ch10-cds-hazard',
    'CDS Present Value': '/chapter10/ch10-cds-pv',
    'CDS Price': '/chapter10/ch10-cds-price'
  },
  'Chapter 11': {
    'MA-Crossover Trading Signal': '/chapter11/ch11-crossover-ma',
    'MA-Crossover + NATR Trading Signal': '/chapter11/ch11-crossover-ma-natr',
    'MA Z-Score Trading Signal': '/chapter11/ch11-zscore-ma',
    'RSI Z-Score Trading Signal': '/chapter11/ch11-zscore-rsi',
    'PPO Z-Score Trading Signal': '/chapter11/ch11-zscore-ppo',
    'Backtesting for Crossover Strategies': '/chapter11/ch11-pnl-crossover',
    'Backtesting for Z-Score Strategies': '/chapter11/ch11-pnl-zscore',
  }
});
