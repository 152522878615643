import { Component, OnInit } from '@angular/core';
import { SlrOutput } from '../../models/ch6.model';
import { Ch6Service } from '../../models/ch6.service'

@Component({
  selector: 'app-ch6-slr-index',
  templateUrl: './ch6-slr-index.component.html',
  styleUrls: ['./ch6-slr-index.component.css']
})
export class Ch6SlrIndexComponent implements OnInit {
  title: string = 'Simple Linear Regression';
  xname: string = 'SPX';
  yname: string = 'HY';

  constructor(private repository: Ch6Service) { }

  ngOnInit() {
    this.getSlrOutput(this.xname, this.yname);
  }

  get slrOutput(): SlrOutput {
    return this.repository.slrIndex;
  }

  getSlrOutput(xname: string, yname: string) {
    this.repository.getSlrIndex(xname, yname);
  }
}
