import { Component, OnInit } from '@angular/core';
import { Ch11Service } from '../../models/ch11.service';
import { StrategyHelper } from '../../models/strategy.helper';

@Component({
  selector: 'app-ch11-zscore-rsi',
  templateUrl: './ch11-zscore-rsi.component.html',
  styleUrls: ['./ch11-zscore-rsi.component.css']
})
export class Ch11ZscoreRsiComponent implements OnInit {
  ticker: string = 'IBM';
  start: string = '2011-06-01';
  end: string = '2013-08-01';
  period: number = 14;
  zEntry: number = 1.5;
  zExit: number = 0;
  data: any;
  options: any;
  private helper: StrategyHelper;

  constructor(private service: Ch11Service) { }

  ngOnInit() {
    this.getData(this.ticker, this.start, this.end, this.period, this.zEntry, this.zExit);
  }

  getData(ticker: string, start: string, end: string, period: number, zEntry: number, zExit: number) {
    this.data = null;
    this.helper = new StrategyHelper();
    this.service.getStrategyRsiZscore(ticker, start, end, period, zEntry, zExit)
      .subscribe(result => {
        this.data = result;
        this.options = this.helper.chartPrice2(this.data, false, 0, [zEntry, zExit]);
      });
  }
}
