import { Component, OnInit } from '@angular/core';
import { Ch9Service } from '../../models/ch9.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch9-option-bermudan',
  templateUrl: './ch9-option-bermudan.component.html',
  styleUrls: ['./ch9-option-bermudan.component.css']
})
export class Ch9OptionBermudanComponent implements OnInit {
  optionResult: any;
  engineType: string = 'binomialJoshi';
  colDefs: any;

  inputColDefs: any = [
    { headerName: "Parameter", field: "param", width: 100 },
    { headerName: "Value", field: "value", width: 100, editable: true },
    { headerName: "Description", field: "desc", width: 300 },
  ];

  inputRows: any = [
    { param: 'Option Type', value: 'C', desc: 'P for a put option, otherwise a call option' },
    { param: 'Spot', value: 100, desc: 'Current price of the underlying asset' },
    { param: 'Rate', value: 0.1, desc: 'Interest rate' },
    { param: 'Div Yield', value: 0.06, desc: 'Continuous dividend yield' },
    { param: 'Vol', value: 0.3, desc: 'Volatility' },
    { param: 'Exercise Frequency', value: 3, desc: 'Exercise frequency (in Month)' },
    { param: 'Exercise Times', value: 4, desc: 'Total exercise times' },
    { param: 'Time Steps', value: 200, desc: 'Time steps used in binomial engines' },
  ];

  private helper: ModelHelper;

  constructor(private repository: Ch9Service) { }

  ngOnInit() {
    this.getOptionResult(this.inputRows, this.engineType);
  }

  getOptionResult(rows: any, engineType: string) {
    this.optionResult = null;
    this.repository.getBermudanOptions(rows[0].value, rows[5].value, rows[6].value, rows[1].value, rows[3].value, rows[2].value,
      rows[4].value, engineType, rows[7].value).subscribe(result => {
        this.optionResult = result.result;
        this.helper = new ModelHelper();
        this.colDefs = this.helper.setColDefs(result.result, 100);
      });
  }
}
