import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MarketData, MarketQuote, AvQuote, AvFxData, AvSectorPerf, QuandlStockData, IsdaRateData } from './market-data.model';

@Injectable({
  providedIn: 'root'
})
export class MarketDataService {
  yahooStock: MarketData[] = [];
  iexStock: MarketData[] = [];
  iexQuote: MarketQuote;
  avStock: MarketData[] = [];
  avStockBar: MarketData[] = [];
  avQuote: AvQuote = null;
  avFx: AvFxData[] = [];
  avFxBar: AvFxData[] = [];
  avSector: AvSectorPerf[];
  quandlStock: QuandlStockData[] = [];
  isdaRate: IsdaRateData[] = [];

  private url;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
  }

  getYahooStock(ticker: string, start: string, end: string, period: string) {
    let url1 = this.url + 'api/yahoostock/' + ticker + '/' + start + '/' + end + '/' + period;
    this.http.get<MarketData[]>(url1).subscribe(result => { this.yahooStock = result });
  }

  getIexStock(ticker: string, range: string) {
    this.http.get<MarketData[]>(this.url + 'api/iexstock/' + ticker + '/' + range).subscribe(result => { this.iexStock = result });
  }

  getIexQuote(ticker: string) {
    this.http.get<MarketQuote>(this.url + 'api/iexquote/' + ticker).subscribe(result => { this.iexQuote = result });
  }

  getAvStock(ticker: string, start: string, end: string, period: string, key: string) {
    let url1 = this.url + 'api/aveod/' + ticker + '/' + start + '/' + end + '/' + period + '/' + key;
    this.http.get<MarketData[]>(url1).subscribe(result => { this.avStock = result });
  }

  getAvStockBar(ticker: string, interval: string, outputsize: string, key: string) {
    let url1 = this.url + 'api/avbar/' + ticker + '/' + interval + '/' + outputsize + '/' + key;
    this.http.get<MarketData[]>(url1).subscribe(result => { this.avStockBar = result });
  }

  getAvQuote(ticker: string, key: string) {
    this.http.get<AvQuote>(this.url + 'api/avquote/' + ticker + '/' + key).subscribe(result => { this.avQuote = result });
  }

  getAvFx(ticker: string, start: string, end: string, period: string, key: string) {
    let url1 = this.url + 'api/avfxeod/' + ticker + '/' + start + '/' + end + '/' + period + '/' + key;
    this.http.get<AvFxData[]>(url1).subscribe(result => { this.avFx = result });
  }

  getAvFxBar(ticker: string, interval: string, outputsize: string, key: string) {
    let url1 = this.url + 'api/avfxbar/' + ticker + '/' + interval + '/' + outputsize + '/' + key;
    this.http.get<AvFxData[]>(url1).subscribe(result => { this.avFxBar = result });
  }

  getAvSector(key: string) {
    this.http.get<AvSectorPerf[]>(this.url + 'api/avsector/perf/' + key).subscribe(result => { this.avSector = result });
  }

  getQuandlStock(ticker: string, start: string, end: string, key: string) {
    let url1 = this.url + 'api/quandlstock/' + ticker + '/' + start + '/' + end + '/' + key;
    this.http.get<QuandlStockData[]>(url1).subscribe(result => { this.quandlStock = result });
  }

  getIsdaRate(currency: string, date: string) {
    let url1 = this.url + 'api/isdarate/' + currency + '/' + date;
    this.http.get<IsdaRateData[]>(url1).subscribe(result => { this.isdaRate = result });
  }
}
