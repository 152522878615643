import { Component, OnInit } from '@angular/core';
import { Ch7Service } from '../../models/ch7.service';

@Component({
  selector: 'app-ch7-ind-sar',
  templateUrl: './ch7-ind-sar.component.html',
  styleUrls: ['./ch7-ind-sar.component.css']
})
export class Ch7IndSarComponent implements OnInit {
  ticker = 'IBM';
  start: string = '2018-01-01';
  end: string = '2018-12-31';
  acceleration: number = 0.01;
  maximum: number = 0.2;

  constructor(private reporsitory: Ch7Service) { }

  ngOnInit() {
    this.getIndicator(this.ticker, this.start, this.end, this.acceleration, this.maximum);
  }

  get indicator(): any {
    return this.reporsitory.sar;
  }

  getIndicator(ticker: string, start: string, end: string, acceleration: number, maximum: number) {
    this.reporsitory.getSar(ticker, start, end, acceleration, maximum);
  }
}
