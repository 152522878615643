import { Component, OnInit } from '@angular/core';
import { SlrOutput } from '../../models/ch6.model';
import { Ch6Service } from '../../models/ch6.service'

@Component({
  selector: 'app-ch6-pca2d-index',
  templateUrl: './ch6-pca2d-index.component.html',
  styleUrls: ['./ch6-pca2d-index.component.css']
})
export class Ch6Pca2dIndexComponent implements OnInit {
  title: string = '2D PCA';
  xname: string = 'SPX';
  yname: string = 'HY';

  constructor(private repository: Ch6Service) { }

  ngOnInit() {
    this.getPca2dOutput(this.xname, this.yname);
  }

  get pca2dOutput(): SlrOutput {
    return this.repository.pca2dIndex;
  }

  getPca2dOutput(xname: string, yname: string) {
    this.repository.getPca2dIndex(xname, yname);
  }
}
