import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { Symbol } from '../../../../models/symbol.model';

@Component({
  selector: 'app-stock-delete-dialog',
  templateUrl: './stock-delete-dialog.component.html',
  styleUrls: ['./stock-delete-dialog.component.css']
})
export class StockDeleteDialogComponent implements OnInit {
  stock: Symbol;
  isdelete: boolean = false;

  constructor(private dialogRef: MatDialogRef<StockDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.stock = data.stock;
  }

  ngOnInit() {
  }

  close() {
    this.isdelete = false;
    this.dialogRef.close(this.isdelete);
  }

  deleteStock() {
    this.isdelete = true;
    this.dialogRef.close(this.isdelete);
  }
}
