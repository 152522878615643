import { Price } from './price.model';

export class Symbol {
  constructor(
    public symbolId?: number,
    public ticker?: string,
    public region?: string,
    public sector?: string,
    public prices?: Price[]
  ) { }
}
