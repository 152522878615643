import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quandl-key',
  templateUrl: './quandl-key.component.html',
  styleUrls: ['./quandl-key.component.css']
})
export class QuandlKeyComponent implements OnInit {
  key: string;
 
  constructor() { }

  ngOnInit() {
    this.key = localStorage.getItem('qdKey');
  }
}
