import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SlrOutput } from '../../models/ch6.model';

@Component({
  selector: 'app-ch6-slr-chart',
  templateUrl: './ch6-slr-chart.component.html',
  styleUrls: ['./ch6-slr-chart.component.css']
})
export class Ch6SlrChartComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() slrOutput: SlrOutput;
  
  options: any;

  constructor() { }

  ngOnInit() {

  }
  ngOnChanges() {
    this.options = this.getOptions();
  }

  getOptions() {
    return {
      title: {
        text: this.title + ' for ' + this.slrOutput.name2 + ' ~ ' + this.slrOutput.name1,
        subtext: 'α = ' + this.slrOutput.alpha.toFixed(4) + ', β = ' + this.slrOutput.beta.toFixed(4)
          + ', R2 = ' + this.slrOutput.rSquared.toFixed(4),
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      dataset: {
        source: this.slrOutput.data
      },
      xAxis: {
        name: this.slrOutput.name1,        
        scale: true,
        axisLine: { onZero: false }
      },
      yAxis: {
        name: this.slrOutput.name2,
        scale: true,
        axisLine: { onZero: false }
      },
      series: [
        {
          type: 'line',
          encode: {
            x: 0,
            y: 2
          },
          showSymbol: false,
          zlevel: 10
        },
        {
          type: 'scatter',
          encode: {
            x: 0,
            y: 1
          },
          symbolSize: 6,
          zlevel: 0
        }
      ]
    }
  }
}
