import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-one-way-binding',
  templateUrl: './one-way-binding.component.html',
  styleUrls: ['./one-way-binding.component.css']
})
export class OneWayBindingComponent implements OnInit {
  public ticker: string = 'IBM';
  public date: Date = new Date('7/14/2015');
  public open: number = 169.43;
  public high: number = 169.54;
  public low: number = 168.24;
  public close: number = 168.61;

  constructor() { }

  ngOnInit() {
  }

  getAvgPrice(): number {
    return (this.open + this.high + this.low + this.close) / 4;
  }

  getColor(price: number): string {
    return price < 169 ? 'red' : 'green';
  }
}
