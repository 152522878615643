import { Component, OnInit } from '@angular/core';
import { Ch10Service } from '../../models/ch10.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch10-cds-pv',
  templateUrl: './ch10-cds-pv.component.html',
  styleUrls: ['./ch10-cds-pv.component.css']
})
export class Ch10CdsPvComponent implements OnInit {
  cdsResult: any;
  colDefs: any;
  private helper: ModelHelper;

  inputCols: any = [
    { headerName: "Parameter", field: "param", width: 150 },
    { headerName: "Value", field: "value", width: 150, editable: true },
  ];
  inputRows: any = [
    { param: 'Protection Size', value: 'buyer' },
    { param: 'CCY', value: 'USD' },
    { param: 'Eval Date', value: '2009-06-15' },
    { param: 'Effective Date', value: '2009-03-20' },   
    { param: 'Maturity', value: '2014-06-20' },
    { param: 'Recovery Rate', value: 0.4 },
    { param: 'Spreads', value: '210' },
    { param: 'Tenors', value: '5Y' },
    { param: 'Notional', value: 10000 },
    { param: 'Coupon Frequency', value: 'quarterly' },
    { param: 'Coupon (in bps)', value: 100 },
    { param: 'Flat Rate', value: -1.0  },
  ];
  constructor(private service: Ch10Service) { }

  ngOnInit() {
    this.getCds(this.inputRows);
  }

  getCds(rows: any) {
    this.cdsResult = null;
    this.service.getCdsPv(rows[0].value, rows[1].value, rows[2].value, rows[3].value, rows[4].value, rows[5].value, rows[6].value,
      rows[7].value, rows[8].value, rows[9].value, rows[10].value, rows[11].value).subscribe(result => {
        this.cdsResult = result.result;
        this.helper = new ModelHelper();
        this.colDefs = this.helper.setColDefs(result.result, 170);
      });
  }
}
