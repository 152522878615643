import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { StockChartHelper } from '../../models/stockChartHelper';

@Component({
  selector: 'app-ch7-ind2-chart',
  templateUrl: './ch7-ind2-chart.component.html',
  styleUrls: ['./ch7-ind2-chart.component.css']
})
export class Ch7Ind2ChartComponent implements OnInit, OnChanges {
  @Input() indicator: any; 
  
  private helper: StockChartHelper = new StockChartHelper();
  private stock: any;
  private legend: any;

  options: any;

  constructor() { }

  ngOnInit() {
    this.stock = this.helper.processCandleData(this.indicator.candles);
    this.options = this.getOptions();
  }

  ngOnChanges() {
    this.stock = this.helper.processCandleData(this.indicator.candles);
    this.options = this.getOptions();
  }

  getOptions() {
    const downColor = '#ec0000';
    const downBorderColor = '#8A0000';
    const upColor = '#00da3c';
    const upBorderColor = '#008F28';

    let candleSeries = {
      name: 'price',
      type: 'candlestick',
      data: this.stock.values,
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: upBorderColor,
        borderColor0: downBorderColor
      }
    };

    let VolumeSeries = {
      name: 'volume',
      type: 'bar',
      xAxisIndex: 1,
      yAxisIndex: 1,
      itemStyle: {
        normal: {
          color: '#7fbe9e'
        },
        emphasis: {
          color: '#140'
        }
      },
      data: this.stock.volume
    }
    
    let series = [];
    series.push(candleSeries);
    let indSeries = this.processIndicatorSeries();
    for (let i in indSeries) {
      series.push(indSeries[i]);
    }
    series.push(VolumeSeries);

    return {
      title: {
        text: this.indicator.ticker + ': Stock Price (' + this.indicator.name + ')',
        left: 'center'
      },
      legend: {
        top: 35,
        right: 30,
        data: this.legend
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      axisPointer: {
        link: { xAxisIndex: 'all' }
      },
      grid: [{
        left: 50,
        right: 20,
        top: '10%',
        height: '53%'
      }, {
        left: 50,
        right: 20,
        height: '20%',
        top: '68%'
      }],
      xAxis: [{
        type: 'category',
        data: this.stock.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }, {
        type: 'category',
        gridIndex: 1,
        data: this.stock.categoryData,
        scale: true,
        boundaryGap: false,
        splitLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        axisLine: { lineStyle: { color: '#777' } },
        splitNumber: 20,
        min: 'dataMin',
        max: 'dataMax'
      }
      ],
      yAxis: [{
        scale: true,
        splitArea: {
          show: true
        }
      }, {
        scale: true,
        gridIndex: 1,
        splitNumber: 2,
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false }
      }
      ],
      dataZoom: [
        {
          show: true,
          type: 'slider',
          y: '93%',
          start: 70,
          end: 100,
          xAxisIndex: [0, 1],
        }
      ],
      series: series
    };
  }


  private processIndicatorSeries() {
    let indicatorSeries = [];
    switch (this.indicator.name) {
      case 'bbands':
        this.legend = ['price', { name: 'upperBand', icon: 'line' }, { name: 'middleBand', icon: 'line' }, { name: 'lowerBand', icon: 'line' }];
        indicatorSeries.push({
          name: 'upperBand',
          type: 'line',
          data: this.indicator.indicator.upperBand,
          showSymbol: false
        });
        indicatorSeries.push({
          name: 'middleBand',
          type: 'line',
          data: this.indicator.indicator.middleBand,
          showSymbol: false
        });
        indicatorSeries.push({
          name: 'lowerBand',
          type: 'line',
          data: this.indicator.indicator.lowerBand,
          showSymbol: false
        });
        break;
      default:
        this.legend = ['price', { name: this.indicator.name, icon: 'line' }];
        indicatorSeries.push({
          name: this.indicator.name,
          type: 'line',
          data: this.indicator.indicator,
          showSymbol: false
        });
        break;
    }
    return indicatorSeries;
  }
}
