import { Component, OnInit } from '@angular/core';
import { Ch7Service } from '../../models/ch7.service';

@Component({
  selector: 'app-ch7-ind-rsi',
  templateUrl: './ch7-ind-rsi.component.html',
  styleUrls: ['./ch7-ind-rsi.component.css']
})
export class Ch7IndRsiComponent implements OnInit {
  ticker = 'IBM';
  start: string = '2018-01-01';
  end: string = '2018-12-31';
  period: number = 14;

  constructor(private reporsitory: Ch7Service) { }

  ngOnInit() {
    this.getIndicator(this.ticker, this.start, this.end, this.period);
  }

  get indicator(): any {
    return this.reporsitory.rsi;
  }

  getIndicator(ticker: string, start: string, end: string, period: number) {
    this.reporsitory.getRsi(ticker, start, end, period);
  }
}
