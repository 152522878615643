import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class Ch7Service {
  private url;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
  }

  ma: any;
  getMa(ticker: string, start: string, end: string, period: number, maType: string) {
    let url1 = this.url + 'api/indicatorma/' + ticker + '/' + start + '/' + end + '/' + period + '/' + maType;
    this.http.get<any>(url1).subscribe(result => { this.ma = result });
  }

  bbands: any;
  getBbands(ticker: string, start: string, end: string, period: number, maType: string, devUp: number, devDown: number) {
    let url1 = this.url + 'api/indicatorbbands/' + ticker + '/' + start + '/' + end + '/' + period + '/' + maType + '/' + devUp + '/' + devDown;
    this.http.get<any>(url1).subscribe(result => { this.bbands = result });
  }

  sar: any;
  getSar(ticker: string, start: string, end: string, acceleration: number, maximum: number) {
    let url1 = this.url + 'api/indicatorsar/' + ticker + '/' + start + '/' + end + '/' + acceleration + '/' + maximum;
    this.http.get<any>(url1).subscribe(result => { this.sar = result });
  }

  adx: any;
  getAdx(ticker: string, start: string, end: string, period: number) {
    let url1 = this.url + 'api/indicatoradx/' + ticker + '/' + start + '/' + end + '/' + period;
    this.http.get<any>(url1).subscribe(result => { this.adx = result });
  }

  apo: any;
  getApo(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod: number, maType: string) {
    let url1 = this.url + 'api/indicatorapo/' + ticker + '/' + start + '/' + end + '/' + fastPeriod + '/' + slowPeriod + '/' + maType;
    this.http.get<any>(url1).subscribe(result => { this.apo = result });
  }

  aroon: any;
  getAroon(ticker: string, start: string, end: string, period: number) {
    let url1 = this.url + 'api/indicatoraroon/' + ticker + '/' + start + '/' + end + '/' + period;
    this.http.get<any>(url1).subscribe(result => { this.aroon = result });
  }

  bop: any;
  getBop(ticker: string, start: string, end: string) {
    let url1 = this.url + 'api/indicatorbop/' + ticker + '/' + start + '/' + end;
    this.http.get<any>(url1).subscribe(result => { this.bop = result });
  }

  cci: any;
  getCci(ticker: string, start: string, end: string, period: number) {
    let url1 = this.url + 'api/indicatorcci/' + ticker + '/' + start + '/' + end + '/' + period;
    this.http.get<any>(url1).subscribe(result => { this.cci = result });
  }

  macd: any;
  getMacd(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod, signalPeriod) {
    let url1 = this.url + 'api/indicatormacd/' + ticker + '/' + start + '/' + end + '/' + fastPeriod + '/' + slowPeriod + '/' + signalPeriod;
    this.http.get<any>(url1).subscribe(result => { this.macd = result });
  }

  rsi: any;
  getRsi(ticker: string, start: string, end: string, period: number) {
    let url1 = this.url + 'api/indicatorrsi/' + ticker + '/' + start + '/' + end + '/' + period;
    this.http.get<any>(url1).subscribe(result => { this.rsi = result });
  }

  stoch: any;
  getStoch(ticker: string, start: string, end: string, fastkPeriod: number, slowkPeriod: number, slowdPeriod: number, maType: string) {
    let url1 = this.url + 'api/indicatorstoch/' + ticker + '/' + start + '/' + end + '/' + fastkPeriod + '/' +
      slowkPeriod + '/' + slowdPeriod + '/' + maType;
    this.http.get<any>(url1).subscribe(result => { this.stoch = result });
  }

  willR: any;
  getWillR(ticker: string, start: string, end: string, period: number) {
    let url1 = this.url + 'api/indicatorwillr/' + ticker + '/' + start + '/' + end + '/' + period;
    this.http.get<any>(url1).subscribe(result => { this.willR = result });
  }

  ad: any;
  getAd(ticker: string, start: string, end: string) {
    let url1 = this.url + 'api/indicatorad/' + ticker + '/' + start + '/' + end;
    this.http.get<any>(url1).subscribe(result => { this.ad = result });
  }

  obv: any;
  getObv(ticker: string, start: string, end: string) {
    let url1 = this.url + 'api/indicatorobv/' + ticker + '/' + start + '/' + end;
    this.http.get<any>(url1).subscribe(result => { this.obv = result });
  }

  atr: any;
  getAtr(ticker: string, start: string, end: string, period: number) {
    let url1 = this.url + 'api/indicatoratr/' + ticker + '/' + start + '/' + end + '/' + period;
    this.http.get<any>(url1).subscribe(result => { this.atr = result });
  }

  natr: any;
  getNatr(ticker: string, start: string, end: string, period: number) {
    let url1 = this.url + 'api/indicatornatr/' + ticker + '/' + start + '/' + end + '/' + period;
    this.http.get<any>(url1).subscribe(result => { this.natr = result });
  }

  trueRange: any;
  getTrueRange(ticker: string, start: string, end: string) {
    let url1 = this.url + 'api/indicatortruerange/' + ticker + '/' + start + '/' + end;
    this.http.get<any>(url1).subscribe(result => { this.trueRange = result });
  }
}
