import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { AvSectorPerf } from '../../models/market-data.model';

@Component({
  selector: 'app-av-sector',
  templateUrl: './av-sector.component.html',
  styleUrls: ['./av-sector.component.css']
})
export class AvSectorComponent implements OnInit {
  displayedCols = ['rank', 'service', 'desc', 'staple', 'energy', 'fin', 'health', 'indu', 'it', 'material', 'util'];
  key: string;
  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.key = localStorage.getItem('avKey');
    if (this.key) {
      if (this.key.length > 6) this.getSector();
    }
  }

  get sector(): AvSectorPerf[] {
    return this.repository.avSector;
  }

  getSector() {
    this.repository.getAvSector(this.key);
  }
}
