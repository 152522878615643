import { Component, OnInit } from '@angular/core';
import { Ch10Service } from '../../models/ch10.service';

@Component({
  selector: 'app-ch10-zc-zspread',
  templateUrl: './ch10-zc-zspread.component.html',
  styleUrls: ['./ch10-zc-zspread.component.css']
})
export class Ch10ZcZspreadComponent implements OnInit {
  zcResult: any;
  zSpread: number = 50;
  curveType: string = 'notDataPoints';

  constructor(private service: Ch10Service) { }

  ngOnInit() {
    this.getZcResult(this.zSpread, this.curveType);
  }

  getZcResult(zSpread: number, curveType: string) {
    this.zcResult = null;
    this.service.getZeroCouponBootstrapZspread(zSpread, curveType).subscribe(result => {
      this.zcResult = result.result;
    });
  }
}
