import { Component, OnInit } from '@angular/core';
import { Ch7Service } from '../../models/ch7.service';

@Component({
  selector: 'app-ch7-ind-bop',
  templateUrl: './ch7-ind-bop.component.html',
  styleUrls: ['./ch7-ind-bop.component.css']
})
export class Ch7IndBopComponent implements OnInit {
  ticker = 'IBM';
  start: string = '2018-01-01';
  end: string = '2018-12-31';

  constructor(private reporsitory: Ch7Service) { }

  ngOnInit() {
    this.getIndicator(this.ticker, this.start, this.end);
  }

  get indicator(): any {
    return this.reporsitory.bop;
  }

  getIndicator(ticker: string, start: string, end: string) {
    this.reporsitory.getBop(ticker, start, end);
  }
}
