import { Component, OnInit } from '@angular/core';
import { Ch7Service } from '../../models/ch7.service';

@Component({
  selector: 'app-ch7-ind-macd',
  templateUrl: './ch7-ind-macd.component.html',
  styleUrls: ['./ch7-ind-macd.component.css']
})
export class Ch7IndMacdComponent implements OnInit {
  ticker = 'IBM';
  start: string = '2018-01-01';
  end: string = '2018-12-31';
  fastPeriod: number = 12;
  slowPeriod: number = 26;
  signalPeriod: number = 9;
  

  constructor(private reporsitory: Ch7Service) { }

  ngOnInit() {
    this.getIndicator(this.ticker, this.start, this.end, this.fastPeriod, this.slowPeriod, this.signalPeriod);

  }

  get indicator(): any {
    return this.reporsitory.macd;
  }

  getIndicator(ticker: string, start: string, end: string, fastPeriod: number, slowPeriod: number, signalPeriod: number) {
    this.reporsitory.getMacd(ticker, start, end, fastPeriod, slowPeriod, signalPeriod);
  }
}
