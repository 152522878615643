import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { MarketData } from '../../models/market-data.model';

@Component({
  selector: 'app-yahoo-stock',
  templateUrl: './yahoo-stock.component.html',
  styleUrls: ['./yahoo-stock.component.css']
})
export class YahooStockComponent implements OnInit {
  ticker = 'A';
  startDate: string = '2018-08-01';
  endDate: string = '2018-11-01';
  period = 'daily';
  displayedCols = ['date', 'open', 'high', 'low', 'close', 'closeAdj', 'volume'];

  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.getStock(this.ticker, this.startDate, this.endDate, this.period);
  }

  get stock(): MarketData[] {
    return this.repository.yahooStock;
  }

  getStock(ticker: string, start: string, end: string, period: string) {
    this.repository.getYahooStock(ticker, start, end, period);
  }
}
