import { Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Symbol } from './symbol.model';
import { Price } from './price.model';
import { IndexData } from './indexData.model';
import { MarketData } from './market-data.model';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {
  stock: Symbol = new Symbol();
  stocks: Symbol[] = [];
  stock1: Symbol = new Symbol(); 
  prices: Price[] = [];
  indexData: IndexData[] = [];
  indexDataAll: IndexData[] = [];
  errorMsg: any = null;
  private url;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
    this.getStocks();
    this.getIndexDataPeriod("2005-01-01", "2005-03-01");
    this.getStock(1);
    this.getIndexData();
  }
  
  getStock(id: number) {
    this.http.get<Symbol>(this.url + 'api/stocks/' + id).subscribe(result => { this.stock = result });
  }

  getStockWithTicker(ticker: string) {
    this.http.get<Symbol>(this.url + 'api/stockwithticker/' + ticker).subscribe(result => { this.stock1 = result });
  }

  getStockAndPriceWithTicker(ticker: string, start: string, end: string) {
    let url1 = this.url + 'api/stockwithticker/' + ticker + '/' + start + '/' + end;
    this.http.get<Symbol>(url1).subscribe(result => { this.stock1 = result });
  }

  getStocks() {
    this.http.get<Symbol[]>(this.url + 'api/stocks').subscribe(result => { this.stocks = result});
  }

  getStockAndPrice(id: number, start: string, end: string) {
    let url1 = this.url + 'api/stocks/' + id + '/' + start + '/' + end;
    this.http.get<Symbol>(url1).subscribe(result => { this.stock = result });
  }

  getIndexData() {
    this.http.get<IndexData[]>(this.url + 'api/indexdata').subscribe(result => { this.indexDataAll = result; });
  }

  getIndexDataPeriod(start: string, end: string) {
    let url1 = this.url + 'api/indexdata' + '/' + start + '/' + end;
    this.http.get<IndexData[]>(url1).subscribe(result => { this.indexData = result });
  }

  createStock(stck: Symbol) {
    let data = {
      ticker: stck.ticker,
      region: stck.region,
      sector: stck.sector
    };
    this.http.post<number>(this.url + 'api/stocks', data).subscribe(result => {
      stck.symbolId = result;
      this.stocks.push(stck);
    });
    return stck;
  }

  updateStock(stck: Symbol) {
    let data = {
      ticker: stck.ticker,
      region: stck.region,
      sector: stck.sector
    };
    this.http.put(this.url + 'api/stocks/' + stck.symbolId, data).subscribe(result => this.getStocks());
  }

  deleteStock(id: number) {
    this.http.delete<any>(this.url + 'api/stocks/' + id).subscribe(result => this.getStocks(),
      error => { this.errorMsg = error.error });
  }

  addStockPrice(stockData: MarketData[]) {
    this.errorMsg = null;
    this.http.post<any>(this.url + 'api/addstockprice', stockData).subscribe(result => console.log(result),
      error => { this.errorMsg = error.error });
  }
}
