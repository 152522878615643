import { Component, OnInit } from '@angular/core';
import { Ch8Service } from '../../models/ch8.service';

@Component({
  selector: 'app-ch8-svm-class',
  templateUrl: './ch8-svm-class.component.html',
  styleUrls: ['./ch8-svm-class.component.css']
})
export class Ch8SvmClassComponent implements OnInit {
  ticker = 'AMAT';
  start: string = '2000-01-01';
  end: string = '2015-12-31';
  trainDate: string = '2014-12-31';
  period: number = 14;
  isNormalized: boolean = false;
  windowSize: number = 3;

  constructor(private repository: Ch8Service) { }

  ngOnInit() {
    this.getMlResult(this.ticker, this.start, this.end, this.period, this.trainDate, this.isNormalized, this.windowSize);  
  }

  get mlResult(): any {
    return this.repository.mlSvmClass;
  }

  getMlResult(ticker: string, start: string, end: string, period: number, trainDate: string,
    isNormalized: boolean, windowSize: number) {
    this.repository.getMlSvmClass(ticker, start, end, period, trainDate, isNormalized, windowSize);
  }
}
