export class AnnInputModel {
  constructor(
    public ticker: string,
    public start: string,
    public end: string,
    public period: number,
    public trainDate: string,
    public isNormalized: boolean,
    public windowSize: number,
    public learningType: string,
    public seed: number,
    public learningRate: number,
    public momentum: number,
    public populationSize: number,
    public iterations: number,
    public loss: number
  ) { }
}
