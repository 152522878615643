import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../../models/repository.service';
import { Symbol } from '../../../models/symbol.model';

@Component({
  selector: 'app-stock-update',
  templateUrl: './stock-update.component.html',
  styleUrls: ['./stock-update.component.css']
})
export class StockUpdateComponent implements OnInit {
  public stockId: number;
  public stck: Symbol;

  constructor(private repository: RepositoryService) {
    this.stck = null;
    this.stockId = 1;
  }

  ngOnInit() {
  }

  get stock() {
    return this.repository.stock;
  }

  getStock() {
    this.stck = null;
    this.repository.getStock(this.stockId);
  }

  updateStock() {
    this.stck = new Symbol(this.stock.symbolId, this.stock.ticker, this.stock.region, this.stock.sector);
    this.repository.updateStock(this.stck);
  }
}
