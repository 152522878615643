import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SlrOutput, MlrOutput } from './ch6.model'

@Injectable({
  providedIn: 'root'
})
export class Ch6Service {
  slrIndex: SlrOutput;
  slrStock: SlrOutput;
  slrStockReturn: SlrOutput;
  pca2dIndex: SlrOutput;
  pca2dStock: SlrOutput;
  pca2dStockReturn: SlrOutput;
  mlrIndex: MlrOutput;
  mlrStock: MlrOutput;
  pcaIndex: MlrOutput;
  pcaStock: MlrOutput;

  private url;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = baseUrl;
  }

  getSlrIndex(xname: string, yname: string) {
    let url1 = this.url + 'api/slrindex/' + xname + '/' + yname;
    this.http.get<SlrOutput>(url1).subscribe(result => { this.slrIndex = result });
  }

  getSlrStock(xticker: string, yticker: string, start: string, end: string) {
    let url1 = this.url + 'api/slrstock/' + xticker + '/' + yticker + '/' + start + '/' + end;
    this.http.get<SlrOutput>(url1).subscribe(result => { this.slrStock = result })
  }

  getSlrStockReturn(xticker: string, yticker: string, start: string, end: string) {
    let url1 = this.url + 'api/slrstockreturn/' + xticker + '/' + yticker + '/' + start + '/' + end;
    this.http.get<SlrOutput>(url1).subscribe(result => { this.slrStockReturn = result })
  }

  getPca2dIndex(xname: string, yname: string) {
    let url1 = this.url + 'api/pca2dindex/' + xname + '/' + yname;
    this.http.get<SlrOutput>(url1).subscribe(result => { this.pca2dIndex = result });
  }

  getPca2dStock(xticker: string, yticker: string, start: string, end: string) {
    let url1 = this.url + 'api/pca2dstock/' + xticker + '/' + yticker + '/' + start + '/' + end;
    this.http.get<SlrOutput>(url1).subscribe(result => { this.pca2dStock = result })
  }

  getPca2dStockReturn(xticker: string, yticker: string, start: string, end: string) {
    let url1 = this.url + 'api/pca2dstockreturn/' + xticker + '/' + yticker + '/' + start + '/' + end;
    this.http.get<SlrOutput>(url1).subscribe(result => { this.pca2dStockReturn = result })
  }

  getMlrIndex() {
    let url1 = this.url + 'api/mlrindex';
    this.http.get<MlrOutput>(url1).subscribe(result => { this.mlrIndex = result });
  }

  getMlrStock(start: string, end: string, tickers: string) {
    let url1 = this.url + 'api/mlrstock/' + start + '/' + end + '/' + tickers;
    this.http.get<MlrOutput>(url1).subscribe(result => { this.mlrStock = result })
  }

  getPcaIndex() {
    let url1 = this.url + 'api/pcaindex';
    this.http.get<MlrOutput>(url1).subscribe(result => { this.pcaIndex = result });
  }

  getPcaStock(start: string, end: string, tickers: string) {
    let url1 = this.url + 'api/pcastock/' + start + '/' + end + '/' + tickers;
    this.http.get<MlrOutput>(url1).subscribe(result => { this.pcaStock = result })
  }
}
