import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-ch10-line-chart',
  templateUrl: './ch10-line-chart.component.html',
  styleUrls: ['./ch10-line-chart.component.css']
})
export class Ch10LineChartComponent implements OnInit, OnChanges {
  @Input() data: any;
  zcOptions: any;
  discOptions: any;

  constructor() { }

  ngOnInit() {
    this.zcOptions = this.getZcOptions(this.data)
    this.discOptions = this.getDiscOptions(this.data);
  }

  ngOnChanges() {
    this.zcOptions = this.getZcOptions(this.data)
    this.discOptions = this.getDiscOptions(this.data);
  }

  getZcOptions(data: any) {
    let data1 = [];
    let data2 = [];
    data.forEach(function (d) {
      data1.push([d['timesToMaturity'], d['zero Coupon Rate: R']]);
      data2.push([d['timesToMaturity'], d['equivalent Rate: Rc']]);
    });
    return {
      legend: {
        top: '11%',
        left: '17%',
        orient: 'vertical',
        data: [{ name: 'R', icon: 'line' }, { name: 'Rc', icon: 'line' }]
      },
      grid: {
        top: 35,
        left: 70,
        right: 10
      },
      title: {
        text: 'Zero-Coupon Yield',
        left: 'center'
      },
      xAxis: {
        type: 'value',
        name: 'Times to Maturity',
        nameLocation: 'center',
        nameGap: '30',
        axisLine: { onZero: false }
      },
      yAxis: {
        type: 'value',
        scale: true,
        name: 'Zero-Coupon Rate',
        nameLocation: 'center',
        nameGap: '50'
      },
      series: [{
        name: 'R',
        type: 'line',
        data: data1,
        showSymbol: false
      },
      {
        name: 'Rc',
        type: 'line',
        data: data2,
        showSymbol: false
      }]
    };
  }

  getDiscOptions(data: any) {
    let data1 = [];
    data.forEach(function (d) {
      data1.push([d['timesToMaturity'], d['discount Rate: B']]);
    });
    return {     
      title: {
        text: 'Discount Yield',
        left: 'center'
      },
      grid: {
        top: 35,
        left: 70,
        right: 10
      },
      xAxis: {
        type: 'value',
        name: 'Times to Maturity',
        nameLocation: 'center',
        nameGap: '30',
        axisLine: { onZero: false }
      },
      yAxis: {
        type: 'value',
        scale: true,
        name: 'Discount Rate',
        nameLocation: 'center',
        nameGap: '50'
      },
      series: [{
        name: 'Discount Rate',
        type: 'line',
        data: data1,
        showSymbol: false
      }]
    };
  }
}
