import { Component, OnInit } from '@angular/core';
import { MarketDataService } from '../../models/market-data.service';
import { MarketQuote } from '../../models/market-data.model';

@Component({
  selector: 'app-iex-quote',
  templateUrl: './iex-quote.component.html',
  styleUrls: ['./iex-quote.component.css']
})
export class IexQuoteComponent implements OnInit {
  ticker = 'A';

  constructor(private repository: MarketDataService) { }

  ngOnInit() {
    this.getQuote(this.ticker);
  }

  get quote(): MarketQuote {
    return this.repository.iexQuote;
  }

  getQuote(ticker: string) {
    this.repository.getIexQuote(ticker);
  }
}
