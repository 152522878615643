export class SlrOutput {
  constructor(
    public name1?: string,
    public name2?: string,
    public alpha?: number,
    public beta?: number,
    public rSquared?: number,
    public data?: any[]
  ) { }
}

export class MlrOutput {
  constructor(
    public tickers: string,
    public rSquared?: number,
    public data?: any[]
  ) { }
}
