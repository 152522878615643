import { Component, OnInit } from '@angular/core';
import { SlrOutput } from '../../models/ch6.model';
import { Ch6Service } from '../../models/ch6.service';

@Component({
  selector: 'app-ch6-slr-stock',
  templateUrl: './ch6-slr-stock.component.html',
  styleUrls: ['./ch6-slr-stock.component.css']
})
export class Ch6SlrStockComponent implements OnInit {
  title: string = 'SLR Prices';
  titleReturn: string = 'SLR Returns';
  ticker1: string = '^GSPC';
  ticker2: string = 'C';
  start: string = '2013-01-01';
  end: string = '2018-01-01';

  constructor(private repository: Ch6Service) { }

  ngOnInit() {
    this.getSlrOutput(this.ticker1, this.ticker2, this.start, this.end);
  }

  get slrOutput(): SlrOutput {
    return this.repository.slrStock;
  }

  get slrOutputReturn(): SlrOutput {
    return this.repository.slrStockReturn;
  }

  getSlrOutput(ticker1: string, ticker2: string, start: string, end: string) {
    this.repository.getSlrStock(ticker1, ticker2, start, end);
    this.repository.getSlrStockReturn(ticker1, ticker2, start, end);
  }

}
