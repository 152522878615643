import { Component, OnInit } from '@angular/core';
import { Ch10Service } from '../../models/ch10.service';
import { ModelHelper } from '../../models/model.helper';

@Component({
  selector: 'app-ch10-cds-hazard',
  templateUrl: './ch10-cds-hazard.component.html',
  styleUrls: ['./ch10-cds-hazard.component.css']
})
export class Ch10CdsHazardComponent implements OnInit {
  data: any;
  colDefs: any;
  private helper: ModelHelper;

  constructor(private service: Ch10Service) { }

  ngOnInit() {
    this.getCds();
  }

  getCds() {
    this.data = null;
    this.service.getCdsHazardRate().subscribe(result => {
      this.data = result.result;
      this.helper = new ModelHelper();
      this.colDefs = this.helper.setColDefs(result.result, 170);
    });
  }
}
