import { Component, OnInit } from '@angular/core';
import { MlrOutput } from '../../models/ch6.model';
import { Ch6Service } from '../../models/ch6.service'

@Component({
  selector: 'app-ch6-mlr-stock',
  templateUrl: './ch6-mlr-stock.component.html',
  styleUrls: ['./ch6-mlr-stock.component.css']
})
export class Ch6MlrStockComponent implements OnInit {
  tickers: string = 'MS,GS,C';
  start: string = '2004-01-01';
  end: string = '2014-01-01';
  title: string = 'MLR';

  constructor(private repository: Ch6Service) { }

  ngOnInit() {
    this.getMlrOutput(this.tickers, this.start, this.end);
  }

  get mlrOutput(): MlrOutput {
    return this.repository.mlrStock;
  }

  getMlrOutput(tickers: string, start: string, end: string) {
    this.repository.getMlrStock(start, end, tickers);
  }
}
