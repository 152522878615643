import { Component, OnInit, Input } from '@angular/core';
import { Symbol } from '../../../models/symbol.model';

@Component({
  selector: 'app-stock-price-child',
  templateUrl: './stock-price-child.component.html',
  styleUrls: ['./stock-price-child.component.css']
})
export class StockPriceChildComponent implements OnInit {
  @Input() stock: Symbol;
  public displayedCols = ['date', 'open', 'high', 'low', 'close', 'closeAdj', 'volume'];

  constructor() { }

  ngOnInit() {
  }
}
