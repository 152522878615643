import { Component, OnInit } from '@angular/core';
import { Ch8AnnRegrHubService } from '../../models/signalr.service';
import { AnnInputModel } from '../../models/ml.model';

@Component({
  selector: 'app-ch8-ann-regr',
  templateUrl: './ch8-ann-regr.component.html',
  styleUrls: ['./ch8-ann-regr.component.css']
})
export class Ch8AnnRegrComponent implements OnInit {
  ticker = 'IBM';
  start: string = '2010-01-01';
  end: string = '2015-12-31';
  trainDate: string = '2014-12-31';
  period: number = 14;
  isNormalized: boolean = true;
  windowSize: number = 3;
  learningType: string = 'levenbergMarquardt';
  seed: number = 3;
  learningRate: number = 0.1;
  momentum: number = 0.1;
  populationSize: number = 100;
  iterations: number = 50;
  loss: number = 0.00001;

  private model: AnnInputModel;
  private subscription: any;
  private data: any;
  options: any;
  updateOptions: any;

  mlResult: any;
  colDefs = [
    { headerName: "Date", field: "date", width: 100 },
    { headerName: "Expect", field: "expected", width: 100 },
    { headerName: "Predict", field: "predicted", width: 100 },
  ];

  constructor(private service: Ch8AnnRegrHubService) { }

  ngOnInit() {
  }

  getMlResult(ticker: string, start: string, end: string, period: number, trainDate: string, isNormalized: boolean, windowSize: number,
    learningType: string, seed: number, learningRate: number, momentum: number, populationSize: number, iterations: number, loss: number) {

    this.data = [];
    this.options = this.getOptions(this.data);
    this.model = new AnnInputModel(ticker, start, end, period, trainDate, isNormalized, windowSize, learningType, seed, learningRate,
      momentum, populationSize, iterations, loss);

    this.subscription = this.service.startStream(this.model).subscribe({
        next: (item) => {
          this.mlResult = null;
          if (item.status == 'starting') {
            this.data = item.lossFunction;
            this.updateOptions = {
              series: [{ data: this.data }]
            }
          } else if (item.status == 'finished') {
            this.mlResult = { results: item.mlOutput };
          }
        },
        complete: () => {
        },
        error: (err) => {
          console.log(err);
        }
      });
  }


  stopService() {
    this.subscription.dispose();
  }


  getOptions(data: any) {
    return {
      title: {
        text: 'Loss Function',
        left: 'center'
      },
      grid: {
        right: 50
      },
      xAxis: {
        type: 'value',
        name: 'epoch',
      },
      yAxis: {
        type: 'value',
        name: 'loss',
        scale: true,
      },
      series: [
        {
          name: 'loss',
          type: 'line',
          data: data,
          showSymbol: false,
        }
      ]
    }
  }
}
