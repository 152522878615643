import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-line3d',
  templateUrl: './line3d.component.html',
  styleUrls: ['./line3d.component.css']
})
export class Line3dComponent implements OnInit {
  options: any;
  private data = [];

  constructor() { }

  ngOnInit() {
    this.getData();
    this.options = this.getOptions();
  }

  getData() {
    for (let i = 0; i < 400; i++) {
      let t = i / 10;
      let x = Math.exp(-t / 30) * Math.cos(t);
      let y = Math.exp(-t / 30) * Math.sin(t);
      let z = t;
      this.data.push([x, y, z]);
    }
  }

  getOptions() {
    return {
      tooltip: {},
      backgroundColor: '#fff',
      visualMap: {
        show: false,
        dimension: 2,
        min: 0,
        max: 30,
        inRange: {
          color: ["#00008F", "#0000FF", "#006FFF", "#00DFFF", "#4FFFAF", "#BFFF3F", "#FFCF00", "#FF5F00", "#EF0000", "#7F0000"]
        }
      },
      xAxis3D: {
        type: 'value'
      },
      yAxis3D: {
        type: 'value'
      },
      zAxis3D: {
        type: 'value'
      },
      grid3D: {
        viewControl: {
          projection: 'perspective',
        },
        boxHeight: 70,
        top: '-10%'
      },
      series: [{
        type: 'line3D',
        data: this.data,
      }]
    }
  }
}
