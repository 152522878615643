import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {
  pie1: any;
  pie2: any;
  pie3: any;
  pie4: any;

  private data: any;
  constructor() { }

  ngOnInit() {
    this.getData();
    this.pie1 = this.getPie('Simple Pie', false, 0, '55%');
    this.pie2 = this.getPie('Doughnut', false, '30%', '55%');
    this.pie3 = this.getPie('Rose Area Pie', 'area', '10%', '55%');
    this.pie4 = this.getPie('Rose Radius Pie', 'radius', '10%', '55%');
  }

  getData() {
    this.data = [
      { value: 30, name: 'Soc. Sec. Tax' },
      { value: 8, name: 'Misc.' },
      { value: 15, name: 'Borrowing' },
      { value: 35, name: 'Income Tax' },
      { value: 12, name: 'Corp. Tax' }     
    ];
  }

  getPie(title: string, rose: any, r1: any, r2: any) {
    return {
      title: {
        text: title,
      },
      tooltip: {},
      legend: {
        x: 'center',
        y: 'bottom'
      },
      series: [{
        data: this.data,
        type: 'pie',
        roseType: rose,
        radius: [r1, r2]
      }]
    };
  }
}
