import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../../models/repository.service';
import { Symbol } from '../../../models/symbol.model';

@Component({
  selector: 'app-stock-create',
  templateUrl: './stock-create.component.html',
  styleUrls: ['./stock-create.component.css']
})
export class StockCreateComponent implements OnInit {
  public ticker: string;
  public region: string;
  public sector: string;
  public stock: Symbol;
 
  constructor(private repository: RepositoryService) { }

  ngOnInit() {
  }

  createStock() {
    let symbol = new Symbol(0, this.ticker, this.region, this.sector);
    this.stock = this.repository.createStock(symbol);
    this.ticker = null;
    this.region = null;
    this.sector = null;
  }  
}
